import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SbAddBtn, SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from "@mui/material";
// import DeleteIcon from '@mui/icons-material/Delete';
import deleteImg from '../../../assets/images/Settings/deleteImage.svg'
import { useDispatch, useSelector } from "react-redux";
import { loadAssignedModulesDropdownData, loadSettingsWorkQueuesRulesFieldNameData, postSettingsWorkQueuesDatas } from "../../../redux/action";
import * as HeaderAuthentication from '../../HeaderAuthentication'
import { ADDED_BY_ID } from "../../Constants";

const enablingOptions = [
    { value: 20, label: "Active" },
    { value: 21, label: "Inactive" }
];

const operators = [
    { value: "equal to", label: "equal to" },
    { value: "not equal to", label: "not equal to" },
    { value: "greater than", label: "greater than" },
    { value: "less than", label: "less than" },
    { value: "greater than equal to", label: "greater than equal to" },
    { value: "less than equal to", label: "less than equal to" },
]

const AddWqRules = (props) => {

    let dispatch = useDispatch();
    const { assignedModulesDropdown } = useSelector((state) => state.data);
    const { getSettingsWorkQuesRulesFieldNameData } = useSelector((state) => state.data);

    const workqueueId = props.workqueueId;
    const settingsSingleWorkQueuesRulesData = props.settingsSingleWorkQueuesRulesData;
    const getSettingsWorkQueuesRulesData = props.getSettingsWorkQueuesRulesData;
    console.log(settingsSingleWorkQueuesRulesData, 'settingsSingleWorkQueuesRulesData')

    const [criteriaList, setCriteriaList] = useState([]); // Store the added criteria
    console.log(criteriaList, 'criteriaList')
    const [moduleId, setModuleId] = useState(null);
    const [selectedModuleName, setSelectedModuleName] = useState(null);
    const [filteredFieldNames, setFilteredFieldNames] = useState([]); // Store filtered fields based on module


    // State variables for selected FieldName, Operator, Value, and Status
    const [selectedFieldName, setSelectedFieldName] = useState(null);
    const [selectedOperator, setSelectedOperator] = useState(null);
    const [selectedValue, setSelectedValue] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [workQueueRuleName, setWorkQueueRuleName] = useState("");
    const [workQueueDescription, setWorkQueueDescription] = useState("");

    // State for error messages
    const [errorMessage, setErrorMessage] = useState("");

    const handleRemoveCriterion = (index) => {
        const updatedCriteria = criteriaList.filter((_, i) => i !== index);
        setCriteriaList(updatedCriteria);
    };

    const handleModuleName = (selectedOption) => {
        const id = selectedOption.value;  // Extract moduleId from selected option
        setModuleId(id);  // Set the selected module ID
        setSelectedModuleName(selectedOption); // Store selected module
        dispatch(loadSettingsWorkQueuesRulesFieldNameData(id)); // Dispatch to load fields for the selected module
    };

    // Update filtered field names based on the fetched field names from the Redux store
    useEffect(() => {
        if (getSettingsWorkQuesRulesFieldNameData && getSettingsWorkQuesRulesFieldNameData.length > 0) {
            setFilteredFieldNames(getSettingsWorkQuesRulesFieldNameData); // Assuming data is loaded and stored in Redux
        }
    }, [getSettingsWorkQuesRulesFieldNameData]);

    useEffect(() => {
        if (moduleId) {
            dispatch(loadSettingsWorkQueuesRulesFieldNameData(moduleId)); // Trigger API call if moduleId is selected
        }
    }, [moduleId, dispatch]);

    useEffect(() => {
        dispatch(loadAssignedModulesDropdownData())
    }, []);

    useEffect(() => {
        if (workqueueId && settingsSingleWorkQueuesRulesData && settingsSingleWorkQueuesRulesData.length > 0) {
            const ruleData = settingsSingleWorkQueuesRulesData[0];
            console.log(ruleData, 'ruleData')
            setWorkQueueRuleName(ruleData.WorkQueueRuleName);
            setWorkQueueDescription(ruleData.WorkQueueDescription);
            setSelectedModuleName({ value: ruleData.ModuleID, label: ruleData.Module });
            setSelectedStatus({ value: ruleData.StatusID, label: ruleData.Status });

            const initialCriteria = ruleData.WorkQueueRules.map(rule => ({
                fieldName: rule.FieldName,
                operator: rule.Operator,
                value: rule.Value,
                // status: { label: rule.Status, value: rule.StatusID },
                status: rule.StatusID,
                isCustom: rule.IsCustom,
                moduleFieldId: rule.ModuleFieldID,
            }));

            setCriteriaList(initialCriteria);
        }
    }, [workqueueId, settingsSingleWorkQueuesRulesData]);

    // Handle changes for Field Name, Operator, Value, and Status
    const handleFieldNameChange = (selectedOption) => {
        setSelectedFieldName(selectedOption);
    };

    const handleOperatorChange = (selectedOption) => {
        setSelectedOperator(selectedOption);
    };

    const handleValueChange = (e) => {
        setSelectedValue(e.target.value);  // Update value from text input
    };

    const handleStatusChange = (selectedOption) => {
        setSelectedStatus(selectedOption);
    };

    // const handleAddToCriteria = () => {
    //     if (selectedFieldName && selectedOperator && selectedValue) {
    //         // Find the selected field data in the getSettingsWorkQuesRulesFieldNameData array
    //         const selectedFieldData = getSettingsWorkQuesRulesFieldNameData.find(field => field.value === selectedFieldName.value);

    //         // Get the IsCustom value. If not found, default to false.
    //         const isCustom = selectedFieldData ? selectedFieldData.IsCustom : false;

    //         // Prepare the new criterion to add
    //         const newCriterion = {
    //             fieldName: selectedFieldName.label,   // Use the label for field name
    //             operator: selectedOperator.label,     // Use the label for operator
    //             value: selectedValue,                 // The value input by the user
    //             status: selectedStatus ? selectedStatus.label : null,  // Status, if selected
    //             isCustom: isCustom,  // Include IsCustom property
    //             moduleFieldId: selectedFieldName.value
    //         };

    //         // Add the new criterion to the criteria list
    //         setCriteriaList([...criteriaList, newCriterion]);

    //         // Clear the selections after adding the criterion
    //         setSelectedFieldName(null);
    //         setSelectedOperator(null);
    //         setSelectedValue("");
    //         setSelectedStatus(null);
    //     } else {
    //         alert("Please select Field Name, Operator, and Value to add to criteria.");
    //     }
    // };


    const handleAddToCriteria = () => {
        if (selectedFieldName && selectedOperator && selectedValue) {
            // Check if the combination of FieldName, Operator, and Value already exists in the criteriaList
            const isDuplicate = criteriaList.some(criterion =>
                criterion.fieldName === selectedFieldName.label &&
                criterion.operator === selectedOperator.label &&
                criterion.value === selectedValue
            );

            if (isDuplicate) {
                // Show error message if combination already exists
                setErrorMessage("This combination of Field Name, Operator, and Value already exists in the criteria list.");
            } else {
                // Find the selected field data in the getSettingsWorkQuesRulesFieldNameData array
                const selectedFieldData = getSettingsWorkQuesRulesFieldNameData.find(field => field.value === selectedFieldName.value);

                console.log(getSettingsWorkQuesRulesFieldNameData, 'getSettingsWorkQuesRulesFieldNameData');
                console.log(selectedFieldData, 'selectedFieldData');
                console.log(selectedFieldData ? selectedFieldData.ModuleFieldID : 'No ModuleFieldID', 'ModuleFieldID');
                
                // Get the IsCustom value. If not found, default to false.
                const isCustom = selectedFieldData ? selectedFieldData.IsCustom : false;
                const moduleFieldId = selectedFieldData ? selectedFieldData.ModuleFieldID : null;
                console.log(moduleFieldId,'modulFieldId')

                // Prepare the new criterion to add
                const newCriterion = {
                    fieldName: selectedFieldName.label,   // Use the label for field name
                    operator: selectedOperator.label,     // Use the label for operator
                    value: selectedValue,                 // The value input by the user
                    status: selectedStatus ? selectedStatus.label : null,  // Status, if selected
                    isCustom: isCustom,  // Include IsCustom property
                    moduleFieldId: moduleFieldId
                };

                // Add the new criterion to the criteria list
                setCriteriaList([...criteriaList, newCriterion]);

                // Clear the selections after adding the criterion
                setSelectedFieldName(null);
                setSelectedOperator(null);
                setSelectedValue("");
                setSelectedStatus(null);
                setErrorMessage(""); // Clear any previous error
            }
        } else {
            setErrorMessage("Please select Field Name, Operator, and Value to add to criteria.");
        }
    };
console.log(criteriaList,'criteriaList')
    const AddSettingsWorkQueueData = (e) => {
        e.preventDefault();

        const data = {
            WorkQueueRuleName: workQueueRuleName,
            ModuleID: selectedModuleName.value,
            WorkQueueID: workqueueId,
            WorkQueueDescription: workQueueDescription,
            StatusID: selectedStatus?.value,
            WorkQueueRules: criteriaList.map(criterion => ({
                WorkQueueID: workqueueId,
                ModuleFieldID: criterion.moduleFieldId, // Assuming this is the Field ID you need
                Operator: criterion.operator,
                Value: criterion.value,
                IsCustom: criterion.isCustom,
                StatusID: criterion.status // Add the Status ID if available
            })),
            AddedByID: ADDED_BY_ID
        };

        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };
        // dispatch(postSettingsWorkQueuesDatas(requestOption, props.handleCloseModal, getSettingsWorkQueuesRulesData, workqueueId));
        console.log(data, 'data')
    }


    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Work Queue Rule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields  customer-branch-plant-form-fields'>
                        <Col>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Module Name</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={assignedModulesDropdown}
                                            placeholder="Select"
                                            value={selectedModuleName}
                                            onChange={handleModuleName}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Rule Name</label>
                                        <Form.Control
                                            type="text"
                                            value={workQueueRuleName}
                                            onChange={(e) => setWorkQueueRuleName(e.target.value)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Description</label>
                                        <Form.Control
                                            type="text"
                                            value={workQueueDescription}
                                            onChange={(e) => setWorkQueueDescription(e.target.value)}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <div className="wq-modal-table-container">
                                <Row>
                                    <Col md={6}>
                                        <div>
                                            <label>Field Name</label>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                options={getSettingsWorkQuesRulesFieldNameData}
                                                placeholder="Select"
                                                value={selectedFieldName}
                                                onChange={handleFieldNameChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div>
                                            <label>Operator</label>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                options={operators}
                                                placeholder="Select"
                                                value={selectedOperator}
                                                onChange={handleOperatorChange}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <div>
                                            <label>Value</label>
                                            {/* <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                // options={enablingOptions}
                                                placeholder="Select"
                                            /> */}
                                            <Form.Control
                                                type="text"
                                                value={selectedValue}
                                                onChange={handleValueChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6} className='d-flex align-items-end'>
                                        <SbAddBtn btnName="Add To Criteria" onClickEffect={handleAddToCriteria} />
                                    </Col>
                                </Row>
                                {/* Error message display */}
                                {errorMessage && <div className="text-danger pt-3">{errorMessage}</div>}
                                <Row className='add-wqRules-table-div'>
                                    <TableContainer component={Paper} sx={{ maxHeight: 440 }} className='table-container add-wqRules-table-container' style={{ boxShadow: "none" }}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className='table-header-div'>Field Name</TableCell>
                                                    <TableCell align="center" className='table-header-div'>Operator</TableCell>
                                                    <TableCell align="center" className='table-header-div'>Value</TableCell>
                                                    <TableCell align="center" className='table-header-div'>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {criteriaList.map((criterion, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{criterion.fieldName}</TableCell>
                                                        <TableCell align="center">{criterion.operator}</TableCell>
                                                        <TableCell align="center">{criterion.value}</TableCell>
                                                        <TableCell align="center">
                                                            {/* <IconButton aria-label="delete" onClick={() => handleRemoveCriterion(index)}>
                                                                <DeleteIcon />
                                                            </IconButton> */}
                                                            <img src={deleteImg} alt="" onClick={() => handleRemoveCriterion(index)} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Row>
                            </div>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Status</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                            value={selectedStatus}  // Ensure selectedStatus has the correct structure
                                            onChange={handleStatusChange}  // Handle status change
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row >
                </Modal.Body >
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={AddSettingsWorkQueueData} />
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default AddWqRules;
