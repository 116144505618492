import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SbApprovalBtn, SBSaveUpdateBtn } from "../Buttons/Buttons";
import customerImg from '../../assets/images/CustomerManagement/customer-img.svg'
import Select from 'react-select';
import { useEffect, useRef, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import calendar from '../../assets/images/Dashboard/calendar-icon.svg'

const options = [
    { label: "Functional Approval", value: "functional_approval" },
    { label: "Financial Approval", value: "financial_approval" },
    { label: "Complaints Approval", value: "complaints_approval" },
    { label: "Escalation Approval", value: "escalation_approval" },
    { label: "Leave Approval", value: "leave_approval" },
];

const roleOptions = [
    { label: "Role", value: "Role" }
]
const contactOptions = [
    { label: "+91", value: "+91" },
    { label: "+1", value: "+1" },
]
const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
]
const AddUserModal = (props) => {
    const [fromDate, setFromDate] = useState(null);
    const [selected, setSelected] = useState([]);

    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const handleSetApproval = () => {
        setShowApprovalModal(true);
    };

    const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);

    const handleChangeStatus = (selectedStatus) => {
        setSelectedStatus(selectedStatus);
    }

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const fileInputRef = useRef(null);
    const [selectedImg, setSelectedImg] = useState(null); // State for the selected image

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImg(reader.result); // Update the state with the new image
            };
            reader.readAsDataURL(file); // Read the file as a data URL
        }
    };

    const [countryCode, setCountryCode] = useState(contactOptions[0]);
    const [emergencyCode, setEmergencyCode] = useState(contactOptions[0]);
    const [contactNumber, setContactNumber] = useState('');
    const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState('');
    const [contactError, setContactError] = useState('');
    const [emergencyError, setEmergencyError] = useState('');

    const validatePhoneNumber = (value, countryCode) => {
        let regex;
        if (countryCode === "+91") {
            // Indian number format (10 digits, starting with 7-9)
            regex = /^(?:\+91|91)?[789]\d{0,2}-?\d{3}-?\d{4}$/;
            // Check for exactly 10 digits
            if (value.replace(/\D/g, '').length !== 10) {
                return false;
            }
        } else if (countryCode === "+1") {
            // U.S. number format
            regex = /^(?:\+1)?\s*\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
        }
        return regex ? regex.test(value) : false;
    };


    const handleChangeCountryCode = (selectedOption) => {
        setCountryCode(selectedOption);
        setContactNumber(''); // Clear the contact number when changing country code
        setContactError(''); // Clear any error message
    };

    const handleChangeEmergencyCode = (selectedOption) => {
        setEmergencyCode(selectedOption);
        setEmergencyPhoneNumber(''); // Clear the emergency number when changing country code
        setEmergencyError(''); // Clear any error message
    };

    const handleContactNumberChange = (e) => {
        const value = e.target.value;
        setContactNumber(value);

        if (value.trim() === '') {
            setContactError('Please enter a contact number.');
        } else if (!validatePhoneNumber(value, countryCode.value)) {
            setContactError('Please enter a valid contact number for the selected country.');
        } else {
            setContactError('');
        }
    };
    const handleEmergencyPhoneChange = (e) => {
        const value = e.target.value;
        setEmergencyPhoneNumber(value);

        // Check for empty input
        if (value.trim() === '') {
            setEmergencyError('Please enter an emergency contact number.');
        }
        // Validate the phone number based on the selected country code
        else if (!validatePhoneNumber(value, emergencyCode.value)) {
            setEmergencyError('Please enter a valid emergency contact number for the selected country.');
        } else {
            setEmergencyError(''); // Clear error if valid
        }
    };


    return (
        <>
            <Modal show={props.open} onHide={props.handleClose} className='add-customer-modal add-user-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col className='customer-form-fields-image-div' onClick={handleUploadClick}>
                            <img src={selectedImg || customerImg} alt="" style={{ width: "100px", height: "100px" }} />
                            <input type="file"
                                hidden
                                ref={fileInputRef}
                                onChange={handleFileChange}
                            />
                            <p>Upload Image</p>
                        </Col>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>First Name</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Middle Name</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Last Name</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Email ID</label>
                                        <Form.Control type="email" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Contact Number</label>
                                        <div className='customer-contact-fields-div'>
                                            <Select
                                                // className='customer-contact-select '
                                                className="react-select-container customer-contact-select"
                                                classNamePrefix="react-select"
                                                value={countryCode}
                                                onChange={handleChangeCountryCode}
                                                options={contactOptions}
                                                placeholder="Select"
                                            />
                                            <Form.Control
                                                type="tel"
                                                value={contactNumber}
                                                onChange={handleContactNumberChange}
                                                isInvalid={!!contactError}
                                            />
                                        </div>

                                    </div>
                                    <div type="invalid" className="text-danger">
                                        {contactError}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Work Address *</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Zip Code</label>
                                        <Form.Control type="number" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className='add-customer-calendar-container'>
                                        <label>Date of Birth</label>
                                        {/* <div className='add-customer-calendar-container'> */}
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            selectsRange
                                            placeholderText='DD/MM/YYYY'
                                            dropdownMode="select"
                                            // startDate={fromDate}
                                            // onChange={(data) => setSelectSearchDrop(data, "date")}
                                            maxDate={new Date()}
                                        />
                                        <img src={calendar} alt="" />
                                        {/* </div> */}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Emergency Contact</label>
                                        <div className='customer-contact-fields-div'>
                                            <Select
                                                // className='customer-contact-select '
                                                className="react-select-container customer-contact-select"
                                                classNamePrefix="react-select"
                                                value={emergencyCode}
                                                onChange={handleChangeEmergencyCode}
                                                options={contactOptions}
                                                placeholder="+91"
                                            />
                                            <Form.Control
                                                type="tel"
                                                value={emergencyPhoneNumber}
                                                onChange={handleEmergencyPhoneChange}
                                                isInvalid={!!emergencyError}
                                            />
                                        </div>
                                    </div>
                                    <div type="invalid" className="text-danger">
                                        {emergencyError}
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Job Title</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            // options={contactOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Role</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // className="user-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            options={roleOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Department</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            // options={contactOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className='add-customer-calendar-container'>
                                        <label>Joining Date</label>
                                        {/* <div className='add-customer-calendar-container'> */}
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            selectsRange
                                            dropdownMode="select"
                                            placeholderText='DD/MM/YYYY'
                                            // startDate={fromDate}
                                            // onChange={(data) => setSelectSearchDrop(data, "date")}
                                            maxDate={new Date()}

                                        />
                                        <img src={calendar} alt="" />
                                        {/* </div> */}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Total Experience DOJ(Years)</label>
                                        <Form.Control type="number" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Probation Period (Months)</label>
                                        <Form.Control type="number" />
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Reporting To</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Home Address</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Status</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            value={selectedStatus.value && selectedStatus}
                                            onChange={handleChangeStatus}
                                            options={statusOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>

                            </Row>
                            <Row>

                                <Col md={8}>
                                    <label>Approval Type</label>
                                    <MultiSelect
                                        options={options}
                                        value={selected}
                                        onChange={setSelected}
                                        labelledBy="Select Approval Type(s)"
                                    />
                                </Col>
                                <Col md={4} className="d-flex justify-content-start align-items-end">
                                    <SbApprovalBtn btnName="Set Approval" onClickEffect={handleSetApproval} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal.Body >
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={props.handleNextStep} />
                </Modal.Footer>
            </Modal >

            <Modal show={showApprovalModal} onHide={() => setShowApprovalModal(false)} className="add-user-submodal">
                <Modal.Header closeButton>
                    <Modal.Title>Set Approval</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selected.map(option => (
                        <div key={option.value} className="approval-option mb-3">
                            <label>{option.label} Level</label>
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                placeholder={option.label}
                            />
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddUserModal;