import React, { useEffect, useRef, useState } from 'react';
import Main from '../Main';
import { Button, Col, Dropdown, Form, Modal, ProgressBar, Row } from 'react-bootstrap';
import searchIcon from '../../assets/images/Dashboard/search-icon.svg'
import calendar from '../../assets/images/Dashboard/calendar-icon.svg'
import columnFilter from '../../assets/images/CustomerManagement/columnFilter.svg'
import valueFilter from '../../assets/images/CustomerManagement/valueFilter.svg'
import '../../assets/css/dashboard.scss'
import '../../assets/css/customermanagement.scss'
import '../../assets/css/usermanagement.scss'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SbCancelBtn, { SbFilterBtn, SBSaveUpdateBtn } from '../Buttons/Buttons';
import UserManagementTable from './UserManagementTable';
import SbDropdownButton from '../Buttons/SbDropdownButton';
import AddUserModal from './AddUserModal';
import Download from '../MainSection/Download/Download';
import { useNavigate } from 'react-router-dom';
import ViewUserModal from './ViewUserModal';
import AddSlocModal from './AddSlocModal';
import UserValueFilterModal from './UserValueFilterModal';
import UserColumnFilterModal from './UserColumnFilterModal';
import crossmark from '../../assets/images/CustomerManagement/crossmark.svg';
import xlsImg from '../../assets/images/UserManagement/xls-img.svg';
import { useDispatch, useSelector } from 'react-redux';
import { loadUserManagementData } from '../../redux/action';

const UserManagement = () => {

    let dispatch = useDispatch();

    const { userData } = useSelector((state) => state.data);
    console.log(userData, 'userData')
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [openSlocModal, setOpenSlocModal] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [showColumnFilterModal, setShowColumnFilterModal] = useState(false);
    const [showValueFilterModal, setShowValueFilterModal] = useState(false);
    const [showCustomerViewDetails, setShowCustomerViewDetails] = useState(false);
    const [files, setFiles] = useState([]);
    const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
    const [progress, setProgress] = useState([]);
    const [droppedFile, setDroppedFile] = useState(null);
    const [uploadComplete, setUploadComplete] = useState(false);
    const fileInputRef = useRef(null);

    const userManagenemtData = () => {
        dispatch(loadUserManagementData());
    }

    useEffect(() => {
        userManagenemtData();
    }, [])

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleOpenViewModal = () => {
        setViewModal(true);
    };

    const handleCloseViewModal = () => {
        setViewModal(false);
    };
    const handleOpenSloc = () => {
        setOpenSlocModal(true);
    };

    const handleCloseSlocModal = () => {
        setOpenSlocModal(false);
    };
    const handleOpenColumnFilterModal = () => {
        setShowFilterMenu(false);
        setShowColumnFilterModal(true);
    };

    const handleCloseColumnFilterModal = () => {
        setShowColumnFilterModal(false);
    };
    const handleOpenValueFilterModal = () => {
        setShowFilterMenu(false);
        setShowValueFilterModal(true);
    };

    const handleCloseValueFilterModal = () => {
        setShowValueFilterModal(false);
    };

    const handleViewDetails = () => {
        setShowCustomerViewDetails(!showCustomerViewDetails);
    }
    const handleCloseViewDetails = () => {
        setShowCustomerViewDetails(false);
    }
    const [showAddUserModa, setAddUserModal] = useState(false)
    const openAddModal = () => {
        setAddUserModal(true);
    };

    const handleOpenBulkUploadModal = () => {
        // Reset the state before opening the modal
        setDroppedFile(null);
        setFiles([]);
        setProgress([]);
        setUploadComplete(false);
        setShowBulkUploadModal(true);
    };

    const handleCloseBulkUploadModal = () => {
        setShowBulkUploadModal(false);
        // Reset states when closing the modal if needed
        setDroppedFile(null);
        setFiles([]);
        setProgress([]);
        setUploadComplete(false);
    };

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        processFiles(newFiles);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const newFiles = Array.from(event.dataTransfer.files);
        processFiles(newFiles);

        if (newFiles.length > 0) {
            setDroppedFile(newFiles[0]);
        }
    };

    const processFiles = (newFiles) => {
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        setProgress((prevProgress) => [
            ...prevProgress,
            ...newFiles.map(() => 0)
        ]);
        simulateProgress(newFiles.length);
    };

    const simulateProgress = (newFileCount) => {
        const currentFileIndex = files.length;
        for (let i = 0; i < newFileCount; i++) {
            let progressValue = 0;
            const interval = setInterval(() => {
                if (progressValue >= 100) {
                    clearInterval(interval);
                    setUploadComplete(true); // Set completion status
                    return;
                }
                progressValue += 10;
                setProgress((prevProgress) => {
                    const newProgress = [...prevProgress];
                    newProgress[currentFileIndex + i] = progressValue;
                    return newProgress;
                });
            }, 500);
        }
    };

    const removeFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        setProgress((prevProgress) => prevProgress.filter((_, i) => i !== index));
    };

    const navigate = useNavigate();
    const handleAnalyticsClick = () => {
        navigate('/licenceDashboard')
    }
    return (
        <div className="customer-management-container">
            <Main>
                <div className='components-download-container'>
                    <Download
                        showDashboardLibraryButton={false}
                        showSaveCustomViewButton={false}
                        showAnalyticsButton={true}
                        showDownloadDropdown={true}
                        onClick={handleAnalyticsClick}
                    />
                </div>
                <div className='customermanagement-content-container'>
                    <div className="customermanagement-content">
                        <Row className='customermanagement-btns-container'>
                            <Col md={3} className='customermanagement-backbtn-div'>
                                <span>User Management</span>
                            </Col>
                            <Col md={9} className='customermanagement-search-filter'>
                                <div className='customermanagement-header-btns filter-btn'>
                                    <SbFilterBtn btnName="Filters" onClickEffect={toggleFilterMenu} />
                                    {showFilterMenu && (
                                        <Dropdown.Menu show className='filter-dropdown-options'>
                                            <Dropdown.Item onClick={handleOpenColumnFilterModal}>
                                                <img src={columnFilter} alt="" />&nbsp;&nbsp;
                                                Column Filter
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={handleOpenValueFilterModal}>
                                                <img src={valueFilter} alt="" />&nbsp;&nbsp;
                                                Value Filter
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    )}
                                </div>
                                <Col md={4} className='filters-col'>
                                    <div className='customermanagement-search'>
                                        <Form.Control type="search" placeholder='Search' />
                                        <img src={searchIcon} alt="" />
                                    </div>
                                </Col>
                                <Col md={2} className='filters-col'>
                                    <div className='calendar-container'>
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            selectsRange
                                            dropdownMode="select"
                                            placeholderText='From'
                                            startDate={fromDate}
                                            // onChange={(data) => setSelectSearchDrop(data, "date")}
                                            maxDate={new Date()}
                                        />
                                        <img src={calendar} alt="" />
                                    </div>
                                </Col>
                                <Col md={2} className='filters-col'>
                                    <div className='calendar-container'>
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            selectsRange
                                            dropdownMode="select"
                                            placeholderText='To'
                                            endDate={toDate}
                                            // onChange={(data) => setSelectSearchDrop(data, "date")}
                                            maxDate={new Date()}
                                        />
                                        <img src={calendar} alt="" />
                                    </div>
                                </Col>
                                {/* <div className='customermanagement-header-btns add-btn' onClick={handleOpenModal}>
                                <Button><img src={add} alt="" className='filter-img' />Add User</Button>
                            </div> */}
                                {/* <SbAddBtn btnName="Add User" onClickEffect={handleOpenModal} /> */}
                                <SbDropdownButton
                                    openAddModal={handleOpenModal}
                                    pageName="User"
                                    btnName="Add User"
                                    addBulkModel={handleOpenBulkUploadModal}
                                />
                            </Col>
                        </Row>
                        <div className='customermanagement-table-div'>
                            <UserManagementTable
                                handleOpenModal={handleOpenModal}
                                handleViewDetails={handleViewDetails}
                                handleOpenViewModal={handleOpenViewModal}
                                handleOpenSloc={handleOpenSloc}
                                handleOpenValueFilterModal={handleOpenValueFilterModal}
                                userData={userData}
                            />
                        </div>
                    </div>
                </div>
            </Main>

            {/* Bulk Upload Modal */}
            <Modal
                show={showBulkUploadModal}
                onHide={handleCloseBulkUploadModal}
                centered
                size="lg"
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
                className='add-customer-modal add-user-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Bulk Upload</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="bulkFileInput">
                        {/* <Form.Label>Upload Files</Form.Label> */}
                        <div
                            className="drag-and-drop-area"
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={handleDrop}
                        >
                            <div className='drag-drop-container'>
                                {droppedFile ? (
                                    <>
                                        <div className="file-info">
                                            <span>{droppedFile.name}</span>&nbsp;&nbsp;
                                            {/* <img src={crossmark} alt="" className='bulk-upload-delete-img' onClick={() => {
                                                setDroppedFile(null); // Clear the dropped file
                                                removeFile(0); // Remove the file from files array if needed
                                            }} /> */}
                                        </div>
                                        {/* Progress bar for the dropped file */}
                                        <ProgressBar
                                            now={progress[0] || 0}
                                            label={`${progress[0] || 0}%`}
                                            variant={uploadComplete ? "success" : "danger"} // Change color based on completion
                                        />
                                        <div className='d-flex justify-content-between align-items-center pt-2'>
                                            <p> ({(droppedFile.size / (1024 * 1024)).toFixed(2)} MB)</p>
                                            <p style={{ color: uploadComplete ? "green" : "red" }}>{uploadComplete ? "Completed" : "Loading..."}</p>
                                        </div>
                                    </>
                                ) : (
                                    <div className='drag-drop-div'>
                                        <img src={xlsImg} alt="" className='xls-Img' />&nbsp;&nbsp;
                                        <p>Drag & Drop Excel file</p>
                                    </div>
                                )}
                            </div>
                            <div className='browse-btn-div'>
                                {!droppedFile && <SBSaveUpdateBtn btnName="Browse Files" onClickEffect={() => fileInputRef.current.click()} />}
                                <input
                                    type="file"
                                    multiple
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                            </div>
                        </div>
                    </Form.Group>

                    {/* Display files with progress bars */}
                    {/* {files.length > 0 && (
                        <div className="uploaded-files">
                            {files.map((file, index) => (
                                <div key={index} className="file-upload-progress">
                                    <div className="file-info">
                                        <span>{file.name}</span>&nbsp;&nbsp;
                                        <p> ({(file.size / (1024 * 1024)).toFixed(2)} MB)</p>
                                        <img src={crossmark} alt="" className='bulk-upload-delete-img' onClick={() => removeFile(index)} />
                                    </div>
                                    <ProgressBar now={progress[index]} label={`${progress[index]}%`} />
                                </div>
                            ))}
                        </div>
                    )} */}
                </Modal.Body>
                {droppedFile && <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={handleCloseBulkUploadModal} />
                    <SBSaveUpdateBtn btnName="Bulk Upload" />
                </Modal.Footer>}
            </Modal>

            {
                isModalOpen && <AddUserModal
                    open={isModalOpen}
                    handleClose={handleCloseModal}
                />
            }
            {
                viewModal && <ViewUserModal
                    viewModal={viewModal}
                    handleCloseViewModal={handleCloseViewModal}
                />
            }
            {
                openSlocModal && <AddSlocModal
                    openSlocModal={openSlocModal}
                    handleCloseSlocModal={handleCloseSlocModal}
                />
            }
            {
                showColumnFilterModal && (
                    <UserColumnFilterModal
                        showColumnFilterModal={showColumnFilterModal}
                        handleCloseColumnFilterModal={handleCloseColumnFilterModal}
                    />
                )
            }
            {
                showValueFilterModal && (
                    <UserValueFilterModal
                        showValueFilterModal={showValueFilterModal}
                        handleCloseValueFilterModal={handleCloseValueFilterModal}
                    />
                )
            }
        </div >
    );
};

export default UserManagement;