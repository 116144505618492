import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../Buttons/Buttons";
import DatePicker from "react-datepicker";
import calendar from '../../assets/images/Dashboard/calendar-icon.svg'
import Select from 'react-select';

const AssignToModal = (props) => {

    return (
        <>
            <Modal show={props.open} onHide={props.handleClose} className='add-customer-modal add-user-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Service Level Agreement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Select Department</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            // options={contactOptions}
                                            placeholder="Select Department"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Select User</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            // options={contactOptions}
                                            placeholder="Select User"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal.Body >
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={props.handleNextStep} />
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default AssignToModal;