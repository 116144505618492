// import React, { useEffect, useRef, useState } from 'react';
// import Chart from 'chart.js/auto';
// import { Dropdown } from 'react-bootstrap';

// const companyOptions = [
//     { value: 'company-a', label: 'Company A' },
//     { value: 'company-b', label: 'Company B' },
//     { value: 'company-c', label: 'Company C' },
// ];

// const yearOptions = [
//     { value: '2022', label: '2022' },
//     { value: '2023', label: '2023' },
//     { value: '2024', label: '2024' },
// ];

// const RelationshipTypeChart = (props) => {
//     const chartRef = useRef(null);
//     const [selectedCompany, setSelectedCompany] = useState(companyOptions[0]);
//     const [selectedYear, setSelectedYear] = useState(yearOptions[0]);

//     useEffect(() => {
//         const chartInstance = new Chart(chartRef.current, {
//             type: 'doughnut',
//             data: {
//                 labels: ['Headquarter', 'Business Unit', 'Branch Office', 'Storage Location'],
//                 datasets: [
//                     {
//                         data: [42, 25, 20, 13],
//                         backgroundColor: ['rgba(58, 201, 119, 1)', 'rgba(255, 94, 94, 1)', 'rgba(255, 159, 0, 1)', 'rgba(52, 58, 64, 1)'],
//                         hoverBackgroundColor: ['rgba(58, 201, 119, 1)', 'rgba(255, 94, 94, 1)', 'rgba(255, 159, 0, 1)', 'rgba(52, 58, 64, 1)'],
//                     },
//                 ],
//             },
//             options: {
//                 cutout: '70%',
//                 plugins: {
//                     legend: {
//                         display: false,
//                     },
//                     tooltip: {
//                         callbacks: {
//                             label: function (tooltipItem) {
//                                 return `${tooltipItem.label}: ${tooltipItem.raw}%`;
//                             },
//                         },
//                     },
//                 },
//             },
//         });

//         return () => {
//             chartInstance.destroy();
//         };
//     }, []);

//     const handleCompanySelect = (company) => {
//         setSelectedCompany(company);
//         // Add logic to update the chart based on the selected company
//     };

//     const handleYearSelect = (year) => {
//         setSelectedYear(year);
//         // Add logic to update the chart based on the selected year
//     };

//     return (
//         <div className='barchart-container relation-type-chart-container'>
//             <h6 className='pt-2'>
//                 {props.moduleName}
//                 {/* Relationship Type */}
//             </h6>
//             <div className='relation-type-chart-btns'>
//                 <Dropdown>
//                     <Dropdown.Toggle id="dropdown-company">
//                         {selectedCompany.label}
//                     </Dropdown.Toggle>
//                     <Dropdown.Menu>
//                         {companyOptions.map(option => (
//                             <Dropdown.Item
//                                 key={option.value}
//                                 onClick={() => handleCompanySelect(option)}
//                                 active={selectedCompany.value === option.value}
//                             >
//                                 {option.label}
//                             </Dropdown.Item>
//                         ))}
//                     </Dropdown.Menu>
//                 </Dropdown>
//                 <Dropdown>
//                     <Dropdown.Toggle id="dropdown-year">
//                         {selectedYear.label}
//                     </Dropdown.Toggle>
//                     <Dropdown.Menu>
//                         {yearOptions.map(option => (
//                             <Dropdown.Item
//                                 key={option.value}
//                                 onClick={() => handleYearSelect(option)}
//                                 active={selectedYear.value === option.value}
//                             >
//                                 {option.label}
//                             </Dropdown.Item>
//                         ))}
//                     </Dropdown.Menu>
//                 </Dropdown>
//             </div>
//             <div className='customer-status-chart'>
//                 <div style={{ width: '50%' }}>
//                     <canvas ref={chartRef} />
//                 </div>
//                 <div style={{ width: '45%', marginLeft: '16px' }} className='relationship-indicators-container'>
//                     <div style={{ marginBottom: '8px' }} className='relationship-indicators-names'>
//                         <span style={{ backgroundColor: '#00A878', width: '12px', height: '12px', display: 'inline-block', borderRadius: '50%' }}></span> Headquarter
//                     </div>
//                     <div style={{ marginBottom: '8px' }} className='relationship-indicators-names'>
//                         <span style={{ backgroundColor: '#F75C4C', width: '12px', height: '12px', display: 'inline-block', borderRadius: '50%' }}></span> Business Unit
//                     </div>
//                     <div style={{ marginBottom: '8px' }} className='relationship-indicators-names'>
//                         <span style={{ backgroundColor: '#F2A20C', width: '12px', height: '12px', display: 'inline-block', borderRadius: '50%' }}></span> Branch Office
//                     </div>
//                     <div style={{ marginBottom: '8px' }} className='relationship-indicators-names'>
//                         <span style={{ backgroundColor: '#4A4A4A', width: '12px', height: '12px', display: 'inline-block', borderRadius: '50%' }}></span> Storage Location
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default RelationshipTypeChart;


import React, { useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Dropdown, Carousel } from 'react-bootstrap';

// Example options
const companyOptions = [
    { value: 'company-a', label: 'Company A' },
    { value: 'company-b', label: 'Company B' },
    { value: 'company-c', label: 'Company C' },
];
const assignedToOptions = [
    { value: 'assignedTo1', label: 'assignedTo1' },
    { value: 'assignedTo2', label: 'assignedTo2' },
    { value: 'assignedTo3', label: 'assignedTo3' },
];

const yearOptions = [
    { value: '2022', label: '2022' },
    { value: '2023', label: '2023' },
    { value: '2024', label: '2024' },
];

const monthOptions = [
    { value: 'january', label: 'January' },
    { value: 'february', label: 'February' },
    { value: 'march', label: 'March' },
    // Add other months...
];

const approverOptions = [
    { value: 'approver-a', label: 'Approver A' },
    { value: 'approver-b', label: 'Approver B' },
    { value: 'approver-c', label: 'Approver C' },
];

const RelationshipTypeChart = ({
    moduleName = 'Relationship Type',
    labels = [],
    colors = [],
    data = [],
    showMonth = false,
    showAssignedTo = false,
    showYear = false,
    showCompany = false,
    showApprover = false
}) => {
    const [selectedCompany, setSelectedCompany] = useState(companyOptions[0]);
    const [selectedAssignedTo, setSelectedAssignedTo] = useState(assignedToOptions[0]);
    const [selectedYear, setSelectedYear] = useState(yearOptions[0]);
    const [selectedMonth, setSelectedMonth] = useState(monthOptions[0]);
    const [selectedApprover, setSelectedApprover] = useState(approverOptions[0]);

    const handleCompanySelect = (company) => {
        setSelectedCompany(company);
        // Add logic to update the chart based on the selected company
    };
    const handleAssignedToSelect = (assignedTo) => {
        setSelectedAssignedTo(assignedTo);
        // Add logic to update the chart based on the selected assignedTo
    };

    const handleYearSelect = (year) => {
        setSelectedYear(year);
        // Add logic to update the chart based on the selected year
    };

    const handleMonthSelect = (month) => {
        setSelectedMonth(month);
        // Add logic to update the chart based on the selected month
    };

    const handleApproverSelect = (approver) => {
        setSelectedApprover(approver);
        // Add logic to update the chart based on the selected approver
    };

    const chartOptions = {
        chart: {
            type: 'pie',
            backgroundColor: 'transparent', // Remove background color
            height: '100%' // Set the chart height (adjust as needed)
        },
        title: {
            text: null, // Remove chart title
        },
        subtitle: {
            text: null, // Remove subtitle
        },
        credits: {
            enabled: false // Disable Highcharts credits
        },
        plotOptions: {
            pie: {
                innerSize: '70%',
                dataLabels: {
                    enabled: false, // Disable data labels inside the chart
                },
                showInLegend: false, // Disable the legend
            },
        },
        colors: colors,
        series: [{
            name: 'Percentage',
            data: labels.map((label, index) => [label, data[index]]),
        }],
    };

    return (
        <div className='barchart-container relation-type-chart-container'>
            <div className='relationship-type-chart-header-div'>
                <h6 className='pt-2'>{moduleName}</h6>
                <div className='relation-type-chart-btns pt-2'>

                    {showCompany && (
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-company">
                                {selectedCompany.label}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {companyOptions.map(option => (
                                    <Dropdown.Item
                                        key={option.value}
                                        onClick={() => handleCompanySelect(option)}
                                        active={selectedCompany.value === option.value}
                                    >
                                        {option.label}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                    {showAssignedTo && (
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-assigned-to">
                                {selectedAssignedTo.label}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {assignedToOptions.map(option => (
                                    <Dropdown.Item
                                        key={option.value}
                                        onClick={() => handleAssignedToSelect(option)}
                                        active={selectedAssignedTo.value === option.value}
                                    >
                                        {option.label}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                    {showYear && (
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-year">
                                {selectedYear.label}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {yearOptions.map(option => (
                                    <Dropdown.Item
                                        key={option.value}
                                        onClick={() => handleYearSelect(option)}
                                        active={selectedYear.value === option.value}
                                    >
                                        {option.label}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </div>
            </div>
            <div className='relation-type-chart-btns pt-2'>
                {showMonth && (
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-month">
                            {selectedMonth.label}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {monthOptions.map(option => (
                                <Dropdown.Item
                                    key={option.value}
                                    onClick={() => handleMonthSelect(option)}
                                    active={selectedMonth.value === option.value}
                                >
                                    {option.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                )}

                {showApprover && (
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-approver">
                            {selectedApprover.label}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {approverOptions.map(option => (
                                <Dropdown.Item
                                    key={option.value}
                                    onClick={() => handleApproverSelect(option)}
                                    active={selectedApprover.value === option.value}
                                >
                                    {option.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </div>
            <div className='customer-status-chart d-flex'>
                <div style={{ width: '60%' }} className='highchart-container'>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </div>
                <div>
                    <div className='carousel-container'>
                        <Carousel>
                            {labels.map((label, index) => (
                                <Carousel.Item key={label}>
                                    <div style={{ marginBottom: '8px' }} className='relationship-indicators-names'>
                                        <span style={{ backgroundColor: colors[index], width: '12px', height: '12px', display: 'inline-block', borderRadius: '50%' }}></span> {label}
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>

                    <div className='relationship-indicators-names carousel-none-div'>
                        {labels.map((label, index) => (
                            <div key={label} style={{ marginBottom: '8px' }}>
                                <span style={{ backgroundColor: colors[index], width: '12px', height: '12px', display: 'inline-block', borderRadius: '50%' }}></span> {label}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RelationshipTypeChart;




