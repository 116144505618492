import React, { useState } from 'react';
import SortIcon from '../../assets/images/Dashboard/chevron-down.svg';

const PriorityStatusBadgeWithDropdown = () => {
    const [selectedStatus, setSelectedStatus] = useState('01');

    // Generate dropdown values from 01 to 10
    const dropdownValues = Array.from({ length: 10 }, (_, i) => (i + 1).toString().padStart(2, '0'));

    const handleStatusChange = (status) => {
        setSelectedStatus(status);
    };

    return (
        <div className="status-badge-with-dropdown" style={{ position: 'relative', display: 'inline-block' }}>
            <span className="status-badge-for-enabling status-badge-for-priority">
                {selectedStatus}
                <span className="dropdown-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={SortIcon} alt="dropdown icon" />
                </span>
                <ul className="dropdown-menu" aria-labelledby="statusDropdown">
                    {dropdownValues.map((value) => (
                        <li key={value}>
                            <button
                                className="dropdown-item"
                                onClick={() => handleStatusChange(value)}
                            >
                                {value}
                            </button>
                        </li>
                    ))}
                </ul>
            </span>
        </div>
    );
};

export default PriorityStatusBadgeWithDropdown;
