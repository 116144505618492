import * as types from "./actionType";

const initialState = {
    // Customer Management
    assignedModulesDropdown: [],
    companyData: [],
    singleCustomerData: {},
    getCountryCodeDropdownData: [],
    getCompanyDropdown: [],
    getGeneratedCompanyCode: [],
    getCompanyCustomFields: [],
    // Customer Management

    // User Management
    userData: [],
    // User Management

    // Settings Department
    departmentData: [],
    singleDepartmentData: [],
    // Settings Department

    // Settings Customer Classification
    customerClassificationData: [],
    singleCustomerClassificationData: {},
    // Settings Customer Classification

    // Settings Designation
    designationData: [],
    departmentDropdown: [],
    reportingDesignationDropdown: [],
    singleDesignationData: {},
    // Settings Designation

    // Settings Issue Types
    issueTypesData: [],
    singleIssueTypesData: {},
    // Settings Issue Types

    // Settings SLA
    SettingSlaData: [],
    SingleSettingSlaData: {},
    // Settings SLA

    // Settings Module
    moduleData: [],
    singleModuleData: {},
    // Settings Module

    // Settings Sub Module
    subModuleData: [],
    singleSubModuleData: {},
    subModuleDropdown: [],
    // Settings Sub Module

    // Settings System Numbering
    systemNumberingData: [],
    // Settings System Numbering

    // Settings Work Queues Rules
    settingsWorkQueuesRulesData: [],
    settingsSingleWorkQueuesRulesData: [],
    getSettingsWorkQuesRulesFieldNameData: [],
    // Settings Work Queues Rules

    // Settings Role
    getRolesData: [],
    getSingleRolesData: {},
    // Settings Role

    // Settings Custom Fields
    getCustomFields: [],
    getSingleCustomFields: {},
    // Settings Custom Fields

    // Settings Leave Policy
    getLeavePolicyData: [],
    getSingleLeavePolicyData: {},
    // Settings Leave Policy

    loading: true,
}

const designReducers = (state = initialState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case types.post_Customer_Data:
        case types.post_Custom_Fields_Value_For_Company:
        case types.get_Assigned_Modules:
            return {
                ...state,
                assignedModulesDropdown: action.payload,
                loading: false,
            };
        case types.get_Company_Data:
            return {
                ...state,
                companyData: action.payload,
                loading: false,
            };
        case types.get_Single_Company_Data:
            return {
                ...state,
                singleCustomerData: action.payload,
                loading: false,
            };
        case types.get_Country_Code:
            return {
                ...state,
                getCountryCodeDropdownData: action.payload,
                loading: false,
            };
        case types.get_Company_Dropdown:
            return {
                ...state,
                getCompanyDropdown: action.payload,
                loading: false,
            };
        case types.get_generated_Company_Code:
            return {
                ...state,
                getGeneratedCompanyCode: action.payload,
                loading: false,
            };
        case types.get_Custom_Fields_For_Company:
            return {
                ...state,
                getCompanyCustomFields: action.payload,
                loading: false,
            };

        // User Management
        case types.get_User_Data:
            return {
                ...state,
                userData: action.payload,
                loading: false,
            };
        // User Management

        // Settings Department
        case types.post_Department_Data:
        case types.get_Department_Data:
            return {
                ...state,
                departmentData: action.payload,
                loading: false,
            }
        case types.get_Single_Department_Data:
            return {
                ...state,
                singleDepartmentData: action.payload,
                loading: false,
            }
        // Settings Department

        // Settings Customer Classification
        case types.post_Customer_Classification_Data:
        case types.get_Customer_Classification_Data:
            return {
                ...state,
                customerClassificationData: action.payload,
                loading: false,
            }
        case types.get_Single_Customer_Classification_Data:
            return {
                ...state,
                singleCustomerClassificationData: action.payload,
                loading: false,
            }
        // Settings Customer Classification

        // Settings Designation
        case types.post_Designation_Data:
        case types.get_Designation_Data:
            return {
                ...state,
                designationData: action.payload,
                loading: false,
            }
        case types.get_Department_Dropdown:
            return {
                ...state,
                departmentDropdown: action.payload,
                loading: false,
            }
        case types.get_Reporting_Designation_Dropdown:
            return {
                ...state,
                reportingDesignationDropdown: action.payload,
                loading: false,
            }
        case types.get_Single_Designation_Data:
            return {
                ...state,
                singleDesignationData: action.payload,
                loading: false,
            }
        // Settings Designation

        // Settings IssueTypes
        case types.post_Issue_Types_Data:
        case types.get_Issue_Type_Data:
            return {
                ...state,
                issueTypesData: action.payload,
                loading: false,
            }
        case types.get_Single_Issue_Types_Data:
            return {
                ...state,
                singleIssueTypesData: action.payload,
                loading: false,
            }
        // Settings IssueTypes

        // Settings SLA
        case types.post_Settings_SLA_Data:
        case types.get_Settings_SLA_Data:
            return {
                ...state,
                SettingSlaData: action.payload,
                loading: false,
            }
        case types.get_Single_Settings_SLA_Data:
            return {
                ...state,
                SingleSettingSlaData: action.payload,
                loading: false,
            }
        // Settings SLA

        // Settings Module
        case types.post_Modules_Data:
        case types.get_Module_Data:
            return {
                ...state,
                moduleData: action.payload,
                loading: false,
            }
        case types.get_Single_Module_Data:
            return {
                ...state,
                singleModuleData: action.payload,
                loading: false,
            }
        // Settings Module

        // Settings Sub Module
        case types.post_Sub_Modules_Data:
        case types.get_Sub_Module_Data:
            return {
                ...state,
                subModuleData: action.payload,
                loading: false,
            }
        case types.get_Single_Sub_Module_Data:
            return {
                ...state,
                singleSubModuleData: action.payload,
                loading: false,
            }
        case types.get_SubModule_Dropdown:
            return {
                ...state,
                subModuleDropdown: action.payload,
                loading: false,
            }
        // Settings Sub Module

        // Settings System Numbering
        case types.post_System_Numbering:
        case types.get_System_Numbering:
            return {
                ...state,
                systemNumberingData: action.payload,
                loading: false,
            }
        // Settings System Numbering


        // Settings Work Queues Rules
        case types.post_Settings_WorkQueues_Rule_Data:
        case types.get_Settings_WorkQueues_Rule:
            return {
                ...state,
                settingsWorkQueuesRulesData: action.payload,
                loading: false,
            }
        case types.get_Settings_Single_WorkQueues_Rule:
            return {
                ...state,
                settingsSingleWorkQueuesRulesData: action.payload,
                loading: false,
            }
        case types.get_Settings_WorkQueues_FieldName_Rule:
            return {
                ...state,
                getSettingsWorkQuesRulesFieldNameData: action.payload,
                loading: false,
            }
        // Settings Work Queues Rules

        // Settings Role
        case types.post_Roles_Data:
        case types.get_Roles_Data:
            return {
                ...state,
                getRolesData: action.payload,
                loading: false,
            }
        case types.get_Single_Roles_Data:
            return {
                ...state,
                getSingleRolesData: action.payload,
                loading: false,
            }
        // Settings Role

        // Settings Custom Fields
        case types.post_CustomFields_Data:
        case types.get_CustomFields_Data:
            return {
                ...state,
                getCustomFields: action.payload,
                loading: false,
            }
        case types.get_Single_CustomFields_Data:
            return {
                ...state,
                getSingleCustomFields: action.payload,
                loading: false,
            }
        // Settings Custom Fields

        // Settings Leave Policy
        case types.post_Leave_Policy_Data:
        case types.get_Leave_Policy_Data:
            return {
                ...state,
                getLeavePolicyData: action.payload,
                loading: false,
            }
        case types.get_Single_Leave_Policy_Data:
            return {
                ...state,
                getSingleLeavePolicyData: action.payload,
                loading: false,
            }
        // Settings Leave Policy

        // eslint-disable-next-line no-fallthrough
        default:
            return state;
    }
}

export default designReducers;