import downloadImg from '../../../assets/images/Dashboard/downloadImg.svg'
import backbtn from '../../../assets/images/Dashboard/back-btn.svg'
import { Button, Col, Dropdown, Row } from 'react-bootstrap';
import dashboardLibrary from '../../../assets/images/Sidebar/dashboardLibrary.svg'
import saveCustomView from '../../../assets/images/Sidebar/saveCustomView.svg'
import chevronUp from '../../../assets/images/Sidebar/chevron-up.svg'
import chevronDown from '../../../assets/images/Sidebar/chevron-down-white.svg'
import { useState } from 'react';
import SaveCustomeViewModal from '../../ContentSection/DashBoard/SaveCustomeViewModal';

const Download = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    return (
        // <div className="download-btn-container">
        //     <div md={4} className='settings-backbtn-div'  >
        //         <img src={backbtn} alt="Back Button" />&nbsp;&nbsp;
        //         <span>Attendance</span>
        //     </div>
        //     <div className="dropdown add-single-bulk">
        //         <button
        //             className="btn btn-add-dropdown"
        //             id="adduser-single-blulk"
        //             onClick={props.onClickEffect && props.onClickEffect}
        //             disabled={props.disableProp}
        //         >
        //             <div className="btn-inline-item d-flex align-items-center">
        //                 <img src={downloadImg} alt="" />
        //                 &nbsp;&nbsp;
        //                 Download
        //             </div>
        //         </button>
        //     </div>
        // </div>

        <Row className='download-btn-container m-0'>
            <Col md={5} className='backbtn-div'>
                <div className='back-navigation-div' onClick={props.onBackClick}>
                    {props.showBackButton && (
                        <img src={backbtn} alt="Back Button" />
                    )}&nbsp;&nbsp;
                    <span>{props.moduleName}</span>
                </div>
            </Col>
            <Col md={7} className='p-0 download-header-btns'>
                <div className='header-btns'>
                    {props.showDashboardLibraryButton && (
                        <Button onClick={props.onClick}>
                            Dashboard Library
                            &nbsp;&nbsp;<img src={dashboardLibrary} alt="" />
                        </Button>
                    )}

                    {/* Conditionally render the Save Custom View button */}
                    {props.showSaveCustomViewButton && (
                        <Button onClick={handleOpenModal}>
                            Save Custom View
                            &nbsp;&nbsp;<img src={saveCustomView} alt="" />
                        </Button>
                    )}
                    {props.showAnalyticsButton && (
                        <Button onClick={props.onClick}>
                            Analytics
                            &nbsp;&nbsp;<img src={saveCustomView} alt="" />
                        </Button>
                    )}
                    {props.showDownloadDropdown && (
                        <Dropdown>
                            <Dropdown.Toggle as={Button} variant="light" className="download-btn">
                                Download
                                &nbsp;&nbsp;<img src={chevronDown} alt="Chevron Down" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item>Images</Dropdown.Item>
                                <Dropdown.Item>PDF</Dropdown.Item>
                                <Dropdown.Item>Excel</Dropdown.Item>
                                <Dropdown.Item>Word Document</Dropdown.Item>
                                <Dropdown.Item>Print</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </div>
            </Col>
            {
                isModalOpen && <SaveCustomeViewModal
                    open={isModalOpen}
                    handleCloseModal={handleCloseModal}
                />
            }
        </Row>
    );
}

export default Download;