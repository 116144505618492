import React from 'react';
import { Modal } from 'react-bootstrap';
import SortIcon from '../../assets/images/Dashboard/chevron-down.svg';
import SbCancelBtn, { SBSaveUpdateBtn } from './Buttons';

const StatusBadgeWithDropdown = ({
    customer,
    activeText = 'Active',
    inactiveText = 'Inactive',
    confirmModalTitle = 'Confirmation',
    confirmModalBody = 'Do you want to change the status?',
    cancelButtonText = 'No',
    confirmButtonText = 'Yes',
    onStatusChange
}) => {
    const [showModal, setShowModal] = React.useState(false);
    const [modalText, setModalText] = React.useState('');
    const [selectedStatus, setSelectedStatus] = React.useState(customer.Status);

    const handleStatusChange = (status) => {
        console.log(status, 'status')
        setModalText(`Do you want to ${status.toLowerCase()} this status?`);
        setSelectedStatus(status);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    // const handleConfirmChange = () => {
    //     // Implement the logic for updating the status
    //     console.log(`Status changed to: ${selectedStatus}`);
    //     setShowModal(false);
    // };

    const handleConfirmChange = () => {
        console.log(`Status changed to: ${selectedStatus}`);
        if (onStatusChange) {
            onStatusChange(selectedStatus); // Pass selectedStatus
        }
        setShowModal(false);
    };


    return (
        <div className="status-badge-with-dropdown" style={{ position: 'relative', display: 'inline-block' }}>
            <span
                className={`status-badge-for-enabling ${selectedStatus === activeText ? 'complete-status' : 'cancel-status'}`}
            >
                {selectedStatus}
                <span className="dropdown-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={SortIcon} alt="dropdown icon" />
                </span>
                <ul className="dropdown-menu" aria-labelledby="statusDropdown">
                    <li>
                        <button
                            className="dropdown-item"
                            onClick={() => handleStatusChange(activeText)}
                        >
                            {activeText}
                        </button>
                    </li>
                    <li>
                        <button
                            className="dropdown-item"
                            onClick={() => handleStatusChange(inactiveText)}
                        >
                            {inactiveText}
                        </button>
                    </li>
                </ul>
            </span>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{confirmModalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalText || confirmModalBody}
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName={cancelButtonText} onClickEffect={handleCloseModal} />
                    <SBSaveUpdateBtn btnName={confirmButtonText} onClickEffect={handleConfirmChange} />
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default StatusBadgeWithDropdown;

