import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem
} from '@mui/material';
import SortIcon from '../../assets/images/Dashboard/chevron-down.svg';
import action from '../../assets/images/Dashboard/action-img.svg';
import edit from '../../assets/images/Dashboard/edit-img.svg';
import view from '../../assets/images/Dashboard/view-img.svg';
import ascToDesc from '../../assets/images/CustomerManagement/ascToDesc.svg';
import descToAsc from '../../assets/images/CustomerManagement/descToAsc.svg';
import SbActionDropdownButton from '../Buttons/SbActionDropdownButton';
import SbStatusDropdown from '../Buttons/SbStatusDropdown';
import PaginationComponent from '../PaginationComponent';

const data = [
    {
        complaintNo: 1001,
        ticketType: 'Complaint Type',
        date: '14/08/24',
        companyRreportedBy: 'Lobotus Company/By Jagadish',
        branch: 'Head Quarter',
        email: 'jagadishgoudavm123@gmail.com',
        contact: '9591617840',
        priority: 'High',
        assignedTo: 'Manager 1',
        tat: '1 day',
        issueType: 'Service',
        status: 'Active',
    },
    {
        complaintNo: 1002,
        ticketType: 'Complaint Type',
        date: '14/08/24',
        companyRreportedBy: 'Lobotus Company/By Jagadish',
        branch: 'Head Quarter',
        email: 'jagadishgoudavm123@gmail.com',
        contact: '9591617840',
        priority: 'High',
        assignedTo: 'Manager 1',
        tat: '1 day',
        issueType: 'Service',
        status: 'Active',
    },
    {
        complaintNo: 1003,
        ticketType: 'Complaint Type',
        date: '14/08/24',
        companyRreportedBy: 'Lobotus Company/By Jagadish',
        branch: 'Head Quarter',
        email: 'jagadishgoudavm123@gmail.com',
        contact: '9591617840',
        priority: 'High',
        assignedTo: 'Manager 1',
        tat: '1 day',
        issueType: 'Service',
        status: 'Active',
    },
    {
        complaintNo: 1004,
        ticketType: 'Complaint Type',
        date: '14/08/24',
        companyRreportedBy: 'Lobotus Company/By Jagadish',
        branch: 'Head Quarter',
        email: 'jagadishgoudavm123@gmail.com',
        contact: '9591617840',
        priority: 'High',
        assignedTo: 'Manager 1',
        tat: '1 day',
        issueType: 'Service',
        status: 'Active',
    },
    {
        complaintNo: 1005,
        ticketType: 'Complaint Type',
        date: '14/08/24',
        companyRreportedBy: 'Lobotus Company/By Jagadish',
        branch: 'Head Quarter',
        email: 'jagadishgoudavm123@gmail.com',
        contact: '9591617840',
        priority: 'High',
        assignedTo: 'Manager 1',
        tat: '1 day',
        issueType: 'Service',
        status: 'Active',
    },
    {
        complaintNo: 1006,
        ticketType: 'Complaint Type',
        date: '14/08/24',
        companyRreportedBy: 'Lobotus Company/By Jagadish',
        branch: 'Head Quarter',
        email: 'jagadishgoudavm123@gmail.com',
        contact: '9591617840',
        priority: 'High',
        assignedTo: 'Manager 1',
        tat: '1 day',
        issueType: 'Service',
        status: 'Active',
    },
    {
        complaintNo: 1007,
        ticketType: 'Complaint Type',
        date: '14/08/24',
        companyRreportedBy: 'Lobotus Company/By Jagadish',
        branch: 'Head Quarter',
        email: 'jagadishgoudavm123@gmail.com',
        contact: '9591617840',
        priority: 'High',
        assignedTo: 'Manager 1',
        tat: '1 day',
        issueType: 'Service',
        status: 'Active',
    },
    {
        complaintNo: 1008,
        ticketType: 'Complaint Type',
        date: '14/08/24',
        companyRreportedBy: 'Lobotus Company/By Jagadish',
        branch: 'Head Quarter',
        email: 'jagadishgoudavm123@gmail.com',
        contact: '9591617840',
        priority: 'High',
        assignedTo: 'Manager 1',
        tat: '1 day',
        issueType: 'Service',
        status: 'Active',
    },
    {
        complaintNo: 1009,
        ticketType: 'Complaint Type',
        date: '14/08/24',
        companyRreportedBy: 'Lobotus Company/By Jagadish',
        branch: 'Head Quarter',
        email: 'jagadishgoudavm123@gmail.com',
        contact: '9591617840',
        priority: 'High',
        assignedTo: 'Manager 1',
        tat: '1 day',
        issueType: 'Service',
        status: 'Active',
    },
    {
        complaintNo: 1010,
        ticketType: 'Complaint Type',
        date: '14/08/24',
        companyRreportedBy: 'Lobotus Company/By Jagadish',
        branch: 'Head Quarter',
        email: 'jagadishgoudavm123@gmail.com',
        contact: '9591617840',
        priority: 'High',
        assignedTo: 'Manager 1',
        tat: '1 day',
        issueType: 'Service',
        status: 'Active',
    },
    {
        complaintNo: 1011,
        ticketType: 'Complaint Type',
        date: '14/08/24',
        companyRreportedBy: 'Lobotus Company/By Jagadish',
        branch: 'Head Quarter',
        email: 'jagadishgoudavm123@gmail.com',
        contact: '9591617840',
        priority: 'High',
        assignedTo: 'Manager 1',
        tat: '1 day',
        issueType: 'Service',
        status: 'Active',
    },
    {
        complaintNo: 1012,
        ticketType: 'Complaint Type',
        date: '14/08/24',
        companyRreportedBy: 'Lobotus Company/By Jagadish',
        branch: 'Head Quarter',
        email: 'jagadishgoudavm123@gmail.com',
        contact: '9591617840',
        priority: 'High',
        assignedTo: 'Manager 1',
        tat: '1 day',
        issueType: 'Service',
        status: 'Active',
    },
    {
        complaintNo: 1013,
        ticketType: 'Complaint Type',
        date: '14/08/24',
        companyRreportedBy: 'Lobotus Company/By Jagadish',
        branch: 'Head Quarter',
        email: 'jagadishgoudavm123@gmail.com',
        contact: '9591617840',
        priority: 'High',
        assignedTo: 'Manager 1',
        tat: '1 day',
        issueType: 'Service',
        status: 'Active',
    },
];

const columns = [
    { id: 'complaintNo', label: 'COMPLAINT NO', align: 'left', minWidth: '150px' },
    { id: 'ticketType', label: 'TICKET TYPE', align: 'left', minWidth: '150px' },
    { id: 'date', label: 'DATE', align: 'left', minWidth: '100px' },
    { id: 'companyRreportedBy', label: 'COMPANY / REPORTED BY ', align: 'left', minWidth: '250px' },
    { id: 'branch', label: 'BRANCH', align: 'left', minWidth: '150px' },
    { id: 'email', label: 'EMAIL', align: 'left', minWidth: '200px' },
    { id: 'contact', label: 'CONTACT', align: 'left', minWidth: '100px' },
    { id: 'priority', label: 'PRIORITY', align: 'left', minWidth: '120px' },
    { id: 'assignedTo', label: 'ASSIGNED TO', align: 'left', minWidth: '150px' },
    { id: 'tat', label: 'TAT', align: 'left', minWidth: '100px' },
    { id: 'issueType', label: 'ISSUE TYPE', align: 'left', minWidth: '120px' },
    { id: 'status', label: 'STATUS', align: 'left', minWidth: '80px' },
    { id: 'action', label: 'ACTION', align: 'left', minWidth: '80px', sortable: false },
];

const ComplaintEscalationTable = (props) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [actionAnchorEl, setActionAnchorEl] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [clickedCell, setClickedCell] = useState({ rowId: null, columnId: null }); // Track clicked cell

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Set initial rows per page
    const [paginatedData, setPaginatedData] = useState([]);

    const handleRequestSort = (event, property) => {
        setCurrentSortColumn(property);
        setAnchorEl(event.currentTarget);
    };

    const handleSortOrderChange = (order) => {
        setOrder(order);
        setOrderBy(currentSortColumn);
        setAnchorEl(null);
    };

    const handleActionClick = (event, customer) => {
        setSelectedCustomer(customer);
        setActionAnchorEl(event.currentTarget);
    };

    const handleActionClose = () => {
        setActionAnchorEl(false);
    };

    const handleMenuItemClick = (action) => {
        if (action === 'edit') {
            console.log('Edit Details for:', selectedCustomer);
            // Implement edit functionality here
        } else if (action === 'view') {
            console.log('View Details for:', selectedCustomer);
            // Implement view functionality here
        }
        handleActionClose();
    };

    const sortedData = React.useMemo(() => {
        const stabilizedData = data.map((el, index) => [el, index]);
        stabilizedData.sort((a, b) => {
            const orderModifier = order === 'asc' ? 1 : -1;
            if (a[0][orderBy] < b[0][orderBy]) {
                return -1 * orderModifier;
            }
            if (a[0][orderBy] > b[0][orderBy]) {
                return 1 * orderModifier;
            }
            return a[1] - b[1];
        });
        return stabilizedData.map((el) => el[0]);
    }, [data, order, orderBy]);

    // Pagination logic
    useEffect(() => {
        const start = page * rowsPerPage; // Updated page logic to 0-based
        const end = start + rowsPerPage;
        setPaginatedData(sortedData.slice(start, end));
    }, [sortedData, page, rowsPerPage]);

    const handlePageChange = (newPage) => {
        setPage(newPage - 1); // Handle 1-based to 0-based page index for slicing
    };
    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to the first page when rows per page changes
    };

    return (
        <>
            <TableContainer component={Paper} sx={{ maxHeight: 440 }} className='table-container'>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }} className='table-header-div'>
                                        {column.label}
                                        {column.sortable !== false && (
                                            <IconButton
                                                size="small"
                                                onClick={(event) => handleRequestSort(event, column.id)}
                                            >
                                                <img src={SortIcon} alt="Sort" />
                                            </IconButton>
                                        )}
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {paginatedData.map((customer) => (
                            <TableRow key={customer.id} className='table-row-data-container'>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        className={clickedCell.rowId === customer.id && clickedCell.columnId === column.id ? 'clicked-value' : ''}
                                        onMouseDown={() => setClickedCell({ rowId: customer.id, columnId: column.id })}
                                        onMouseUp={() => setClickedCell({ rowId: null, columnId: null })}
                                        onMouseLeave={() => setClickedCell({ rowId: null, columnId: null })}
                                    >
                                        {column.id === 'status' ? (
                                            <span
                                            // className={`status-badge ${customer.status === 'Active' ? 'complete-status' : 'cancel-status'}`}
                                            >
                                                <SbStatusDropdown />
                                            </span>
                                        ) : column.id === 'action' ? (
                                            <SbActionDropdownButton
                                                options={[
                                                    { type: 'Edit' },
                                                    { type: 'View' },
                                                    { type: 'Download' }
                                                ]}
                                                openAddModal={props.handleOpenModal}
                                                addBulkModel={props.handleOpenViewModal}
                                            />
                                        ) : (
                                            <span
                                                onClick={() => {
                                                    setClickedCell({ rowId: customer.id, columnId: column.id }); // Track clicked cell
                                                    props.handleOpenValueFilterModal(); // Open modal
                                                }}
                                                style={{ cursor: "pointer" }}>
                                                {customer[column.id]}
                                            </span>
                                        )}
                                    </TableCell>

                                ))}
                            </TableRow>
                        ))} */}

                        {paginatedData.map((customer) => (
                            <TableRow key={customer.complaintNo} className='table-row-data-container'>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        className={clickedCell.rowId === customer.complaintNo && clickedCell.columnId === column.id ? 'clicked-value' : ''}
                                        onMouseDown={() => setClickedCell({ rowId: customer.complaintNo, columnId: column.id })}
                                        onMouseUp={() => setClickedCell({ rowId: null, columnId: null })}
                                        onMouseLeave={() => setClickedCell({ rowId: null, columnId: null })}
                                    >
                                        {column.id === 'status' ? (
                                            <SbStatusDropdown />
                                        ) : column.id === 'action' ? (
                                            <SbActionDropdownButton
                                                options={[{ type: 'Edit' }, { type: 'View' }, { type: 'Download' }]}
                                                openAddModal={props.handleOpenModal}
                                                addBulkModel={props.handleOpenViewModal}
                                                addAttachmentModal={props.handleOpenAttachmentUploadModal}
                                            />
                                        ) : (
                                            <span
                                                onClick={() => {
                                                    setClickedCell({ rowId: customer.complaintNo, columnId: column.id });
                                                    props.handleOpenValueFilterModal(); // Open modal
                                                }}
                                                style={{ cursor: "pointer" }}>
                                                {customer[column.id]}
                                            </span>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem onClick={() => handleSortOrderChange('asc')}>
                        <img src={ascToDesc} alt="" />&nbsp;&nbsp;
                        Ascending to Descending
                    </MenuItem>
                    <MenuItem onClick={() => handleSortOrderChange('desc')}>
                        <img src={descToAsc} alt="" />&nbsp;&nbsp;
                        Descending to Ascending
                    </MenuItem>
                </Menu>
            </TableContainer>
            {/* Use PaginationComponent */}
            <PaginationComponent
                totalItems={data.length}
                itemsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </>
    );
};

export default ComplaintEscalationTable;
