export const postExcomatixAuth = {
    "Content-Type": "application/json",
    "X-Api-Key": "f238b70537400820398f84fa1e1d12344314914eb7803d96b2ad497efed288d5",
};

export const excomatixAuth = {
    "Content-Type": "application/json",
    "X-Api-Key": "f238b70537400820398f84fa1e1d12344314914eb7803d96b2ad497efed288d5",
};

export const getRequestOptions = {
    headers: excomatixAuth,
    "method": "GET",
    "mode": "cors"
};