import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import * as HeaderAuthentication from '../../HeaderAuthentication'
import { useDispatch } from "react-redux";
import { postSettingsSlaCategoriesData } from "../../../redux/action";
import { ADDED_BY_ID } from "../../Constants";

const enablingOptions = [
    { value: 14, label: "Active" },
    { value: 15, label: "Inactive" }
]
const AddSlaCategories = (props) => {

    const dispatch = useDispatch();
    const getSettingsSlaData = props.getSettingsSlaData;
    const SingleSettingSlaData = props.SingleSettingSlaData;
    const settingsSlaId = props.settingsSlaId;
    const [slaCategoryName, setSlaCategoryName] = useState('');
    const [status, setStatus] = useState(null);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (settingsSlaId && SingleSettingSlaData) {
            setSlaCategoryName(SingleSettingSlaData[0]?.SLACategoriesName);
            const statusOption = enablingOptions.find(option => option.value === SingleSettingSlaData[0]?.StatusID);
            setStatus(statusOption || null);
            console.log(statusOption, 'statusOption')
        } else {
            // Reset fields for adding new classification
            setSlaCategoryName("");
            setStatus(null);
        }
    }, [settingsSlaId, SingleSettingSlaData]);

    const AddSlaCategoriesData = (e) => {
        e.preventDefault();
        const newErrors = {};

        // Validate required fields
        if (!slaCategoryName) newErrors.slaCategoryName = "SLA Category Name is required.";

        // Check if there are errors
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        const data = {
            SLACategoriesID: settingsSlaId,
            SLACategoriesName: slaCategoryName,
            StatusID: parseInt(status?.value),
            AddedByID: ADDED_BY_ID
        }
        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };
        dispatch(postSettingsSlaCategoriesData(requestOption, props.handleCloseModal, getSettingsSlaData, settingsSlaId));
    }


    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{settingsSlaId ? 'Edit SLA Categories' : 'Add SLA Categories'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>SLA Categories</label>
                                        <Form.Control
                                            type="text"
                                            value={slaCategoryName}
                                            // onChange={(e) => setSlaCategoryName(e.target.value)}
                                            onChange={(e) => {
                                                setSlaCategoryName(e.target.value);
                                                setErrors(prev => ({ ...prev, slaCategoryName: "" })); // Clear specific error
                                            }}
                                            isInvalid={!!errors.slaCategoryName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.slaCategoryName}
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                            </Row>
                            {settingsSlaId && <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Enable / Disable</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                            value={status}
                                            onChange={(selectedOption) => setStatus(selectedOption)}
                                        />
                                    </div>
                                </Col>
                            </Row>}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={AddSlaCategoriesData} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddSlaCategories;
