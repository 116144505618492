import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SbApprovalBtn, SBSaveUpdateBtn } from "../Buttons/Buttons";
import customerImg from '../../assets/images/CustomerManagement/customer-img.svg'
import Select from 'react-select';
import { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import calendar from '../../assets/images/Dashboard/calendar-icon.svg'

const options = [
    { label: "Functional Approval", value: "functional_approval" },
    { label: "Financial Approval", value: "financial_approval" },
    { label: "Complaints Approval", value: "complaints_approval" },
    { label: "Escalation Approval", value: "escalation_approval" },
    { label: "Leave Approval", value: "leave_approval" },
    { label: "Leave Approval", value: "leave_approval" },
    { label: "Leave Approval", value: "leave_approval" },
    { label: "Leave Approval", value: "leave_approval" },
];

const roleOptions = [
    { label: "Role", value: "Role" }
]
const contactOptions = [
    { label: "+91", value: "+91" }
]
const ViewUserModal = (props) => {
    const [fromDate, setFromDate] = useState(null);
    const [selected, setSelected] = useState([]);
    const [showApprovalModal, setShowApprovalModal] = useState(false);

    const handleSetApproval = () => {
        setShowApprovalModal(true);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    return (
        <>
            <Modal show={props.viewModal} onHide={props.handleCloseViewModal} className='add-customer-modal add-user-modal' >
                <Modal.Header closeButton>
                    <Modal.Title>View User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col className='customer-form-fields-image-div'>
                            <img src={customerImg} alt="" />
                            <input type="file" hidden />
                            <p>Upload Image</p>
                        </Col>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>First Name</label>
                                        <span>Jagadish</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Last Name</label>
                                        <span>Mudigoudra</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Email ID</label>
                                        <span>jagadishgoudavm123@gmail.com</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>

                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Contact Number</label>
                                        <div className='customer-contact-fields-div'>
                                            <span>+91</span>
                                            <span>9591617840</span>
                                        </div>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Work Address *</label>
                                        <span>Lobotus pvt ltd race-coarse road, majestic,bangalore.</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Zip Code</label>
                                        <span>58118</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Date of Birth</label>
                                        <span>23/03/1996</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Emergency Contact</label>
                                        <div className='customer-contact-fields-div'>
                                            <span>+91</span>
                                            <span>9591617840</span>
                                        </div>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Job Title</label>
                                        <span>UI Developer</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Role</label>
                                        <span>Developer</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Department</label>
                                        <span>Technical</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Joining Date</label>
                                        <span>24/04/2023</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Total Experience DOJ(Years)</label>
                                        <span>1.6 years</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Probation Period (Months)</label>
                                        <span>3 months</span>
                                    </div>
                                    <hr />
                                </Col>

                            </Row>
                            <Row>
                                <Col md={8}>
                                    <div className="view-sla-data">
                                        <label>Home Address</label>
                                        <span>Taj nagar,Ektha nagar, Unkal Hubballi</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Status</label>
                                        <span>Active</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal.Body >
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseViewModal} />
                    <SBSaveUpdateBtn btnName="Ok" onClickEffect={props.handleNextStep} />
                </Modal.Footer>
            </Modal >

            <Modal show={props.viewModal} onHide={props.handleCloseViewModal} className="add-customer-modal add-user-submodal" >
                <Modal.Header closeButton>
                    <Modal.Title>Set Approval</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {options.map(option => (
                        <div key={option.value} className="customer-form-fields align-items-start">
                            <div className="view-sla-data">
                                <label>{option.label} Level</label>
                                <span>{option.label}</span>
                                <hr />
                            </div>
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ViewUserModal;




// import { Col, Modal, Row } from "react-bootstrap";
// import SbCancelBtn, { SBSaveUpdateBtn } from "../Buttons/Buttons";
// import customerImg from '../../assets/images/CustomerManagement/customer-img.svg';
// import { useState } from "react";

// const options = [
//     { label: "Functional Approval", value: "functional_approval" },
//     { label: "Financial Approval", value: "financial_approval" },
//     { label: "Complaints Approval", value: "complaints_approval" },
//     { label: "Escalation Approval", value: "escalation_approval" },
//     { label: "Leave Approval", value: "leave_approval" },
// ];

// const ViewUserModal = (props) => {
//     const [showApprovalModal, setShowApprovalModal] = useState(false);

//     const openApprovalModal = () => {
//         setShowApprovalModal(true);
//     };

//     const closeApprovalModal = () => {
//         setShowApprovalModal(false);
//     };

//     return (
//         <>
//             <Modal show={props.viewModal} onHide={props.handleCloseViewModal} className='add-customer-modal'>
//                 <Modal.Header closeButton>
//                     <Modal.Title>View User</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Row className='customer-form-fields'>
//                         <Col className='customer-form-fields-image-div'>
//                             <img src={customerImg} alt="Customer" />
//                             <input type="file" hidden />
//                             <p>Upload Image</p>
//                         </Col>
//                         <Col>
//                             <Row>
//                                 <Col md={4}>
//                                     <div className="view-sla-data">
//                                         <label>First Name</label>
//                                         <span>Jagadish</span>
//                                     </div>
//                                     <hr />
//                                 </Col>
//                                 <Col md={4}>
//                                     <div className="view-sla-data">
//                                         <label>Last Name</label>
//                                         <span>Mudigoudra</span>
//                                     </div>
//                                     <hr />
//                                 </Col>
//                                 <Col md={4}>
//                                     <div className="view-sla-data">
//                                         <label>Email ID</label>
//                                         <span>jagadishgoudavm123@gmail.com</span>
//                                     </div>
//                                     <hr />
//                                 </Col>
//                             </Row>
//                             <Row>
//                                 <Col md={4}>
//                                     <div className="view-sla-data">
//                                         <label>Contact Number</label>
//                                         <div className='customer-contact-fields-div'>
//                                             <span>+91</span>
//                                             <span>9591617840</span>
//                                         </div>
//                                     </div>
//                                     <hr />
//                                 </Col>
//                                 <Col md={4}>
//                                     <div className="view-sla-data">
//                                         <label>Work Address *</label>
//                                         <span>Lobotus Pvt Ltd, Race-course Road, Majestic, Bangalore.</span>
//                                     </div>
//                                     <hr />
//                                 </Col>
//                                 <Col md={4}>
//                                     <div className="view-sla-data">
//                                         <label>Zip Code</label>
//                                         <span>58118</span>
//                                     </div>
//                                     <hr />
//                                 </Col>
//                             </Row>
//                             <Row>
//                                 <Col md={4}>
//                                     <div className="view-sla-data">
//                                         <label>Date of Birth</label>
//                                         <span>23/03/1996</span>
//                                     </div>
//                                     <hr />
//                                 </Col>
//                                 <Col md={4}>
//                                     <div className="view-sla-data">
//                                         <label>Emergency Contact</label>
//                                         <div className='customer-contact-fields-div'>
//                                             <span>+91</span>
//                                             <span>9591617840</span>
//                                         </div>
//                                     </div>
//                                     <hr />
//                                 </Col>
//                                 <Col md={4}>
//                                     <div className="view-sla-data">
//                                         <label>Job Title</label>
//                                         <span>UI Developer</span>
//                                     </div>
//                                     <hr />
//                                 </Col>
//                             </Row>
//                             <Row>
//                                 <Col md={4}>
//                                     <div className="view-sla-data">
//                                         <label>Role</label>
//                                         <span>Developer</span>
//                                     </div>
//                                     <hr />
//                                 </Col>
//                                 <Col md={4}>
//                                     <div className="view-sla-data">
//                                         <label>Department</label>
//                                         <span>Technical</span>
//                                     </div>
//                                     <hr />
//                                 </Col>
//                                 <Col md={4}>
//                                     <div className="view-sla-data">
//                                         <label>Joining Date</label>
//                                         <span>24/04/2023</span>
//                                     </div>
//                                     <hr />
//                                 </Col>
//                             </Row>
//                             <Row>
//                                 <Col md={4}>
//                                     <div className="view-sla-data">
//                                         <label>Total Experience DOJ (Years)</label>
//                                         <span>1.6 years</span>
//                                     </div>
//                                     <hr />
//                                 </Col>
//                                 <Col md={4}>
//                                     <div className="view-sla-data">
//                                         <label>Probation Period (Months)</label>
//                                         <span>3 months</span>
//                                     </div>
//                                     <hr />
//                                 </Col>
//                             </Row>
//                             <Row>
//                                 <Col md={8}>
//                                     <div className="view-sla-data">
//                                         <label>Home Address</label>
//                                         <span>Taj Nagar, Ektha Nagar, Unkal Hubballi</span>
//                                     </div>
//                                     <hr />
//                                 </Col>
//                                 <Col md={4}>
//                                     <div className="view-sla-data">
//                                         <label>Status</label>
//                                         <span>Active</span>
//                                     </div>
//                                     <hr />
//                                 </Col>
//                             </Row>
//                         </Col>
//                     </Row>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseViewModal} />
//                     <SBSaveUpdateBtn btnName="Set Approval" onClickEffect={openApprovalModal} />
//                 </Modal.Footer>
//             </Modal>

//             <Modal show={showApprovalModal} onHide={closeApprovalModal} className="add-user-submodal">
//                 <Modal.Header closeButton>
//                     <Modal.Title>Set Approval</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {options.map(option => (
//                         <div key={option.value} className="customer-form-fields align-items-start">
//                             <div className="view-sla-data">
//                                 <label>{option.label} Level</label>
//                                 <span>{option.label}</span>
//                                 <hr />
//                             </div>
//                         </div>
//                     ))}
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <SbCancelBtn btnName="Cancel" onClickEffect={closeApprovalModal} />
//                     <SBSaveUpdateBtn btnName="Save" onClickEffect={closeApprovalModal} />
//                 </Modal.Footer>
//             </Modal>
//         </>
//     );
// };

// export default ViewUserModal;
