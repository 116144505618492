import React, { useRef, useState } from 'react';
import Main from '../Main';
import { Col, Dropdown, Form, Modal, ProgressBar, Row } from 'react-bootstrap';
import searchIcon from '../../assets/images/Dashboard/search-icon.svg'
import calendar from '../../assets/images/Dashboard/calendar-icon.svg'
import columnFilter from '../../assets/images/CustomerManagement/columnFilter.svg'
import valueFilter from '../../assets/images/CustomerManagement/valueFilter.svg'
import '../../assets/css/dashboard.scss'
import '../../assets/css/customermanagement.scss'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SbCancelBtn, { SbAddBtn, SbFilterBtn, SBSaveUpdateBtn } from '../Buttons/Buttons';
import ComplaintEscalationTable from './ComplaintEscalationTable';
import Select from 'react-select';
import AddComplaintEsaclation from './AddComplaintEsaclation';
import Download from '../MainSection/Download/Download';
import { useNavigate } from 'react-router-dom';
import ViewComplaints from './ViewComplaints';
import ComplaintValueFilterModal from './ComplaintValueFilterModal';
import ComplaintColumnFilterModal from './ComplaintColumnFilterModal';

const statusOptions = [
    { value: "All", label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "In-Process", label: "In-Process" },
    { value: "Resolved", label: "Resolved" },
    { value: "Re-Open", label: "Re-Open" },
    { value: "Cancel", label: "Cancel" },
]
const ComplaintEscalation = () => {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [viewComplaintModal, setViewComplaintModal] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [showColumnFilterModal, setShowColumnFilterModal] = useState(false);
    const [showValueFilterModal, setShowValueFilterModal] = useState(false);
    const [showCustomerViewDetails, setShowCustomerViewDetails] = useState(false);
    const [businessUnitModal, setBusinessUnitModal] = useState(false);
    const [branchPlantModal, setBranchPlantModal] = useState(false);
    const [files, setFiles] = useState([]);
    const [showAttachmentUploadModal, setShowAttachmentUploadModal] = useState(false);
    const [progress, setProgress] = useState([]);
    const [droppedFile, setDroppedFile] = useState(null);
    const [uploadComplete, setUploadComplete] = useState(false);
    const fileInputRef = useRef(null);

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleOpenViewModal = () => {
        setViewComplaintModal(true);
    };

    const handleCloseViewModal = () => {
        setViewComplaintModal(false);
    };
    const handleOpenColumnFilterModal = () => {
        setShowFilterMenu(false);
        setShowColumnFilterModal(true);
    };

    const handleCloseColumnFilterModal = () => {
        setShowColumnFilterModal(false);
    };
    const handleOpenValueFilterModal = () => {
        setShowFilterMenu(false);
        setShowValueFilterModal(true);
    };

    const handleCloseValueFilterModal = () => {
        setShowValueFilterModal(false);
    };

    const handleViewDetails = () => {
        setShowCustomerViewDetails(!showCustomerViewDetails);
    }
    const navigate = useNavigate();
    const handleAnalyticsClick = () => {
        navigate('/complaintDashboard')
    }

    const handleOpenAttachmentUploadModal = () => {
        // Reset the state before opening the modal
        setDroppedFile(null);
        setFiles([]);
        setProgress([]);
        setUploadComplete(false);
        setShowAttachmentUploadModal(true);
    };

    const handleCloseBulkUploadModal = () => {
        setShowAttachmentUploadModal(false);
        // Reset states when closing the modal if needed
        setDroppedFile(null);
        setFiles([]);
        setProgress([]);
        setUploadComplete(false);
    };

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        processFiles(newFiles);
    };

    const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg']; // Allowed file types
    const maxFiles = 5; // Maximum number of files

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const newFiles = Array.from(event.dataTransfer.files);
        console.log('Dropped files:', newFiles);

        // Check for duplicates and validate file types
        const existingFiles = new Set(files.map(file => file.name));
        const uniqueFiles = newFiles.filter(file => {
            const isValidType = validFileTypes.includes(file.type);
            const isDuplicate = existingFiles.has(file.name);
            return isValidType && !isDuplicate;
        });

        console.log('Unique files to be added:', uniqueFiles);

        // Check if adding these files exceeds the limit
        if (files.length + uniqueFiles.length > maxFiles) {
            alert(`You can only upload a maximum of ${maxFiles} files.`);
            return;
        }

        if (uniqueFiles.length > 0) {
            processFiles(uniqueFiles);
        }
    };

    const processFiles = (newFiles) => {
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        setProgress((prevProgress) => [
            ...prevProgress,
            ...newFiles.map(() => 0)
        ]);
        simulateProgress(newFiles.length);
    };

    const simulateProgress = (newFileCount) => {
        const currentFileIndex = files.length;
        for (let i = 0; i < newFileCount; i++) {
            let progressValue = 0;
            const interval = setInterval(() => {
                if (progressValue >= 100) {
                    clearInterval(interval);
                    setUploadComplete(true); // Set completion status
                    return;
                }
                progressValue += 10;
                setProgress((prevProgress) => {
                    const newProgress = [...prevProgress];
                    newProgress[currentFileIndex + i] = progressValue;
                    return newProgress;
                });
            }, 500);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation(); // Stop the event from bubbling up
    };

    const removeFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        setProgress((prevProgress) => prevProgress.filter((_, i) => i !== index));
    };
    return (
        <div className="customer-management-container">
            {!showCustomerViewDetails && <Main>
                <div className='components-download-container'>
                    <Download
                        showDashboardLibraryButton={false}
                        showSaveCustomViewButton={false}
                        showAnalyticsButton={true}
                        showDownloadDropdown={true}
                        onClick={handleAnalyticsClick}
                    />
                </div>
                <div className='customermanagement-content-container'>
                    <div className="customermanagement-content">
                        <Row className='customermanagement-btns-container'>
                            <Col md={3} className='customermanagement-backbtn-div'>
                                {/* <img src={backbtn} alt="" /> */}
                                <span>Complaint / Escalation</span>
                            </Col>
                            <Col md={9} className='customermanagement-search-filter'>
                                <div className='customermanagement-header-btns filter-btn'>
                                    <SbFilterBtn btnName="Filters" onClickEffect={toggleFilterMenu} />
                                    {showFilterMenu && (
                                        <Dropdown.Menu show className='filter-dropdown-options'>
                                            <Dropdown.Item onClick={handleOpenColumnFilterModal}>
                                                <img src={columnFilter} alt="" />&nbsp;&nbsp;
                                                Column Filter
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={handleOpenValueFilterModal}>
                                                <img src={valueFilter} alt="" />&nbsp;&nbsp;
                                                Value Filter
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    )}
                                </div>
                                <Col md={3} className='filters-col'>
                                    <div className='customermanagement-search'>
                                        <Form.Control type="search" placeholder='Search' />
                                        <img src={searchIcon} alt="" className='p-2' />
                                    </div>
                                </Col>
                                <Col md={2} className='filters-col'>
                                    <div className='calendar-container'>
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            selectsRange
                                            placeholderText='From'
                                            startDate={fromDate}
                                            // onChange={(data) => setSelectSearchDrop(data, "date")}
                                            maxDate={new Date()}
                                        />
                                        <img src={calendar} alt="" />
                                    </div>
                                </Col>
                                <Col md={2} className='filters-col'>
                                    <div className='calendar-container'>
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            selectsRange
                                            placeholderText='To'
                                            endDate={toDate}
                                            // onChange={(data) => setSelectSearchDrop(data, "date")}
                                            maxDate={new Date()}
                                        />
                                        <img src={calendar} alt="" />
                                    </div>
                                </Col>
                                {/* <div style={{ width: "100%" }}> */}
                                {/* <Col md={3} className='filters-col'> */}
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    placeholder="Select Status"
                                    options={statusOptions}
                                    style={{ width: "100%" }}
                                />
                                {/* </Col> */}
                                {/* </div> */}
                                <SbAddBtn btnName="Add Complaints" onClickEffect={handleOpenModal} />
                            </Col>
                        </Row>
                        <div className='customermanagement-table-div'>
                            <ComplaintEscalationTable
                                handleOpenModal={handleOpenModal}
                                handleViewDetails={handleViewDetails}
                                handleOpenViewModal={handleOpenViewModal}
                                handleOpenValueFilterModal={handleOpenValueFilterModal}
                                handleOpenAttachmentUploadModal={handleOpenAttachmentUploadModal}
                            />
                        </div>
                    </div>
                </div>
            </Main>}
            {/* Upload Attachment Modal */}
            <Modal
                show={showAttachmentUploadModal}
                onHide={handleCloseBulkUploadModal}
                centered
                size="lg"
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
                className='add-customer-modal add-user-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Upload Attachments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="bulkFileInput">
                        <div
                            className="drag-and-drop-area"
                            // onDragOver={(e) => e.preventDefault()}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                        >
                            <div className='drag-drop-container'>
                                {files.length > 0 ? (
                                    files.map((file, index) => (
                                        <div key={index} className="file-info">
                                            <span>{file.name}</span>&nbsp;&nbsp;
                                            <ProgressBar
                                                now={progress[index] || 0}
                                                label={`${progress[index] || 0}%`}
                                                variant={progress[index] === 100 ? "success" : "danger"}
                                            />
                                            <div className='d-flex justify-content-between align-items-center pt-2'>
                                                <p> ({(file.size / (1024 * 1024)).toFixed(2)} MB)</p>
                                                <p style={{ color: progress[index] === 100 ? "green" : "red" }}>
                                                    {progress[index] === 100 ? "Completed" : "Loading..."}
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className='drag-drop-div'>
                                        <p>Drag & Drop files(Supports only JPG,JPEG,PNG)</p>
                                    </div>
                                )}
                            </div>
                            <div className='browse-btn-div'>
                                <SBSaveUpdateBtn btnName="Browse Files" onClickEffect={() => fileInputRef.current.click()} />
                                <input
                                    type="file"
                                    multiple
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                     accept=".jpg,.jpeg,.png"
                                />
                            </div>
                        </div>
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={handleCloseBulkUploadModal} />
                    <SBSaveUpdateBtn btnName="Upload Attachment" />
                </Modal.Footer>
            </Modal>
            {
                isModalOpen && <AddComplaintEsaclation
                    open={isModalOpen}
                    handleCloseModal={handleCloseModal}
                />
            }
            {
                viewComplaintModal && <ViewComplaints
                    viewComplaintModal={viewComplaintModal}
                    handleCloseViewModal={handleCloseViewModal}
                />
            }
            {showColumnFilterModal && (
                <ComplaintColumnFilterModal
                    showColumnFilterModal={showColumnFilterModal}
                    handleCloseColumnFilterModal={handleCloseColumnFilterModal}
                />
            )}
            {showValueFilterModal && (
                <ComplaintValueFilterModal
                    showValueFilterModal={showValueFilterModal}
                    handleCloseValueFilterModal={handleCloseValueFilterModal}
                />
            )}
            {/* {showCustomerViewDetails && (
                <CustomerViewDetails
                    showCustomerViewDetails={showCustomerViewDetails}
                    handleCloseViewDetails={handleCloseViewDetails}
                />
            )} */}
        </div >
    );
};

export default ComplaintEscalation;