import React, { useEffect, useRef, useState } from 'react';
import { Col, Modal, Row, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import headQuarter from '../../assets/images/CustomerManagement/head-quarter.svg'
import customerImg from '../../assets/images/CustomerManagement/customer-img.svg'
import dotImg from '../../assets/images/CustomerManagement/dot-img.svg'
import businessUnit from '../../assets/images/CustomerManagement/business-unit.svg'
import branchInformation from '../../assets/images/CustomerManagement/branch-plant-information.svg'
import storageLocationImg from '../../assets/images/CustomerManagement/storage-location.svg'
import crossmark from '../../assets/images/CustomerManagement/crossmark.svg'
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import calendar from '../../assets/images/Dashboard/calendar-icon.svg'
import SbCancelBtn, { SbAddBtn, SBPreviousBtn, SBSaveUpdateBtn } from '../Buttons/Buttons';
import { loadAssignedModulesDropdownData, loadCountryCodeDropdownData, loadCustomFieldsForCompany, loadGeneratedCompanyCode, postCustomerManagementDatas } from '../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import * as HeaderAuthentication from '../HeaderAuthentication'
import { MultiSelect } from "react-multi-select-component";
import moment from 'moment/moment';
import autoFillImg from '../../assets/images/CustomerManagement/auto-fill-icon.svg'
import CustomFieldsForCustomer from './CustomFieldsForCustomer';

const statusOptions = [
  { value: 5, label: "Active" },
  { value: 6, label: "Inactive" },
  { value: 7, label: "Disabled" },
]

const reportingTypeOptionsPlant = [
  { value: "Company", label: "Company" },
  { value: "Business Unit", label: "Business Unit" },
];

const reportingTypeOptionsSloc = [
  { value: "Company", label: "Company" },
  { value: "Business Unit", label: "Business Unit" },
  { value: "Branch", label: "Branch" },
];

const AddCustomerModal = ({ open, handleClose, singleCustomerData, companyCode, customerManagementData, companyId }) => {

  let dispatch = useDispatch();
  const { assignedModulesDropdown } = useSelector((state) => state.data);
  const { getCountryCodeDropdownData } = useSelector((state) => state.data);
  const { getGeneratedCompanyCode } = useSelector((state) => state.data);
  const { getCompanyCustomFields } = useSelector((state) => state.data);

  const [customerData, setCustomerData] = useState({
    AddedByID: 1,
    Company_Code: '',
    Company_Name: '',
    Company_ContactPerson: '',
    Company_EmailID: '',
    Company_CountryCode: '+91',
    Company_Mobile: '',
    Company_NoOfLicenses: 0,
    Company_Classification: '',
    Company_Address: '',
    Company_ZipCode: '',
    Company_StartDate: '',
    Company_EndDate: '',
    Company_Website: '',
    Company_StatusIDFK: null,
    AssignedModuleIDs: "",
    Image: "",
    BusinessUnits: [
      {
        UnitName: '',
        UnitCode: '',
        StatusIDFK: null
      }
    ],
    Branches: [
      {
        BranchCode: '',
        BranchName: '',
        EmailID: '',
        PinCode: '',
        CountryCode: '+91',
        Phone: '',
        ReportingType: '',
        ReportingTo: '',
        NoOfLicenses: 0,
        Address: '',
        ZipCode: '',
        StatusIDFK: null,
      },
    ],
    Slocs: [
      {
        StorageLocationCode: '',
        StorageLocationName: '',
        ReportingType: '',
        ReportingTo: '',
        StatusIDFK: null,
      },
    ],
  });

  const [customFields, setCustomFields] = useState([]);
  const [fieldValues, setFieldValues] = useState({});

  useEffect(() => {
    dispatch(loadAssignedModulesDropdownData())
    dispatch(loadCountryCodeDropdownData())
    dispatch(loadCustomFieldsForCompany())
  }, [])

  useEffect(() => {
    if (getCompanyCustomFields) {
      setCustomFields(getCompanyCustomFields);
    }
  }, [getCompanyCustomFields]);

  // Trigger the API call when image is clicked
  const handleGenerateCodeClick = () => {
    dispatch(loadGeneratedCompanyCode());  // Dispatch the API call
  };

  // Update the input field with the generated company code (if available)
  React.useEffect(() => {
    if (getGeneratedCompanyCode) {
      setCustomerData((prevData) => ({
        ...prevData,
        Company_Code: getGeneratedCompanyCode,  // Update with the generated code
      }));
    }
  }, [getGeneratedCompanyCode]);  // Trigger when `generatedCompanyCode` changes


  useEffect(() => {
    if (companyCode && singleCustomerData) {
      const customer = singleCustomerData.Customers && singleCustomerData?.Customers.length > 0
        ? singleCustomerData.Customers[0]
        : null;

      const parseDate = (dateString) => {
        const datePart = dateString.split(' ')[0]; // MM/DD/YYYY
        const [month, day, year] = datePart.split('/'); // Split into month, day, year
        return `${day}/${month}/${year}`; // Return in DD/MM/YYYY format
      };

      // const startDate = customer?.StartDate ? parseDate(customer.StartDate) : '';
      // const endDate = customer?.EndDate ? parseDate(customer.EndDate) : '';

      // const startDate = customer?.StartDate ? moment(parseDate(customer.StartDate)).format('DD-MM-YYYY') : '';
      // const endDate = customer?.EndDate ? moment(parseDate(customer.EndDate)).format('DD-MM-YYYY') : '';

      setCustomerData(prevData => ({
        ...prevData,
        Company_Code: customer?.CompanyCode || '',
        Company_Name: customer?.CompanyName || '',
        Company_ContactPerson: customer?.ContactPerson || '',
        Company_EmailID: customer?.EmailID || '',
        Company_CountryCode: customer?.CountryCode || '+91',
        Company_Mobile: customer?.Mobile || '',
        Company_NoOfLicenses: customer?.NoOfLicenses || 0,
        Company_Classification: customer?.Classification || '',
        Company_Address: customer?.Address || '',
        Company_ZipCode: customer?.ZipCode || '',
        Company_StartDate: customer?.StartDate || "",
        Company_EndDate: customer?.EndDate || "",
        Company_Website: customer?.Website || '',
        Company_StatusIDFK: customer?.StatusID || '',
        AssignedModuleIDs: customer?.AssignedModuleIDs || "",
        Image: customer?.Image || "",
        // Image: "",
        Profile: customer?.Profile || "",
        BusinessUnits: Array.isArray(singleCustomerData?.BusinessUnits) && singleCustomerData?.BusinessUnits.length > 0
          ? singleCustomerData.BusinessUnits.map(unit => ({
            ...unit,
            StatusIDFK: unit.StatusID || null, // Set StatusIDFK from the data or default to null
          }))
          : [{ UnitName: '', UnitCode: '', StatusIDFK: null }],
        Branches: Array.isArray(singleCustomerData?.Branch) && singleCustomerData?.Branch.length > 0
          ? singleCustomerData.Branch.map(branch => ({
            ...branch,
            ReportingTo: branch.ReportingToCode || '', // Ensure ReportingTo is initialized
            StatusIDFK: branch.StatusID || null, // Set StatusIDFK from the data or default to null
          }))
          : [{
            BranchCode: '',
            BranchName: '',
            EmailID: '',
            PinCode: '',
            CountryCode: '+91',
            Phone: '',
            ReportingType: '',
            ReportingTo: '', // Initialize ReportingTo as an empty string
            NoOfLicenses: 0,
            Address: '',
            ZipCode: '',
            StatusIDFK: null
          }],
        Slocs: Array.isArray(singleCustomerData.Slocs) && singleCustomerData?.Slocs.length > 0
          ? singleCustomerData.Slocs.map(sloc => ({
            ...sloc,
            ReportingTo: sloc.ReportingToCode || '',
            StatusIDFK: sloc.StatusID || null, // Set StatusIDFK from the data or default to null
          }))
          : [{
            StorageLocationCode: '',
            StorageLocationName: '',
            ReportingType: '',
            ReportingTo: '',
            StatusIDFK: null,
          }],
      }));
    } else {
      // Uncomment to reset to initial state if needed
      // setCustomerData({ ...initialCustomerDataState });
    }
  }, [companyCode, singleCustomerData]);


  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);

  const handleChangeStatus = (selectedStatus) => {
    setSelectedStatus(selectedStatus);
  }

  // const handleInputChange = (name) => (e) => {
  //   const { value } = e.target;
  //   setCustomerData((prevCustomer) => ({
  //     ...prevCustomer,
  //     [name]: name === 'Company_NoOfLicenses' ? Number(value) : value, // Convert to number if it's NoOfLicenses
  //   }));
  // };

  const handleInputChange = (name) => (e) => {
    if (e && e.value) {
      // Handle the Select component
      setCustomerData((prevCustomer) => ({
        ...prevCustomer,
        [name]: e.value,
      }));
    } else if (e) {
      // Handle the input fields
      const { value } = e.target;
      setCustomerData((prevCustomer) => ({
        ...prevCustomer,
        [name]: name === 'Company_NoOfLicenses' ? Number(value) : value,
      }));
    }

    // Clear the error for that specific field when user starts typing
    setFieldErrors((prev) => ({
      ...prev,
      [name]: undefined, // Clear the error for this field
    }));
  };

  // const assignedModuleValues = customerData.AssignedModuleIDs
  //   ? customerData?.AssignedModuleIDs.split(',').map(value => {
  //     return { value: parseInt(value.trim()), label: assignedModulesDropdown.find(option => option.value === parseInt(value.trim()))?.label };
  //   })
  //   : [];

  const assignedModuleValues = customerData.AssignedModuleIDs
    ? customerData.AssignedModuleIDs.split(',').map(value => {
      const moduleValue = parseInt(value.trim());
      const label = assignedModulesDropdown
        ? assignedModulesDropdown.find(option => option.value === moduleValue)?.label
        : undefined; // Handle the case where assignedModulesDropdown is undefined

      return { value: moduleValue, label };
    })
    : [];

  // const assignedModuleValues = customerData.AssignedModuleIDs
  //   ? customerData?.AssignedModuleIDs.split(',').map(value => {
  //     const id = parseInt(value.trim());
  //     const option = assignedModulesDropdown?.find(option => option.value === id); // Use optional chaining

  //     return {
  //       value: id,
  //       label: option ? option.label : `Module ${id}` // Fallback if option is not found
  //     };
  //   })
  //   : [];

  const handleModuleChange = (selected) => {
    const moduleIds = selected.map(module => module.value).join(',');
    setCustomerData((prevCustomer) => ({
      ...prevCustomer,
      AssignedModuleIDs: moduleIds
    }));

    // You may want to clear any existing error for this field
    setFieldErrors({
      ...fieldErrors,
      AssignedModuleIDs: null,
    });

  };

  const handleDateChange = (date, type) => {
    const formattedDate = moment(date).format('DD-MM-YYYY');
    setCustomerData((prev) => ({
      ...prev,
      [type]: formattedDate, // Update the specific date field
    }));

    // Clear the error for the specific date field
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [type]: null, // Set the error message to null
    }));
  };

  // const [businessUnits, setBusinessUnits] = useState([]);

  // const handleAddBusinessUnit = () => {
  //   setBusinessUnits(prevUnits => [...prevUnits, {}]); // Adding an empty object as a placeholder
  // };
  // const handleDeleteBusinessUnit = (index) => {
  //   setBusinessUnits((prevUnits) => prevUnits.filter((_, i) => i !== index));
  // };


  // Function to get options based on the selected reporting type
  const getUnitCodesOptions = (reportingType) => {
    if (reportingType === "Company") {
      return [{ value: customerData.Company_Code, label: customerData.Company_Code }];
    } else if (reportingType === "Business Unit") {
      return customerData.BusinessUnits.map(unit => ({
        value: unit.UnitCode,
        label: unit.UnitCode,
      }));
    }
    return [];
  };

  const handleAddBusinessUnit = () => {
    setCustomerData((prevData) => ({
      ...prevData,
      BusinessUnits: [
        ...prevData.BusinessUnits,
        { UnitName: '', UnitCode: '', StatusIDFK: null }, // Adding a new unit
      ],
    }));
  };

  const handleDeleteBusinessUnit = (index) => {
    setCustomerData((prevData) => ({
      ...prevData,
      BusinessUnits: prevData.BusinessUnits.filter((_, i) => i !== index),
    }));
  };

  const handleBusinessUnitChange = (index, field, value) => {
    setCustomerData((prevData) => {
      const updatedBusinessUnits = prevData.BusinessUnits.map((unit, i) => {
        if (i === index) {
          return { ...unit, [field]: value }; // Update the specific field
        }
        return unit;
      });

      return {
        ...prevData,
        BusinessUnits: updatedBusinessUnits,
      };
    });

    // Clear the error for the field being edited
    setFieldErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[`BusinessUnits[${index}].${field}`]; // Clear the specific error
      return newErrors;
    });
  };

  // const [branchPlant, setBranchPlant] = useState([]);

  // const handleAddBranchPlant = () => {
  //   setBranchPlant(prevUnits => [...prevUnits, {}]); // Adding an empty object as a placeholder
  // };

  // const handleDeleteBranchPlantInformation = (index) => {
  //   setBranchPlant((prevUnits) => prevUnits.filter((_, i) => i !== index))
  // }

  // Function to get options based on the selected reporting type
  const getPlantCodesOptions = (reportingType) => {
    switch (reportingType) {
      case "Company":
        return [{ value: customerData.Company_Code, label: customerData.Company_Code }];
      case "Business Unit":
        return customerData.BusinessUnits.map(unit => ({
          value: unit.UnitCode,
          label: unit.UnitCode,
        }));
      case "Branch":
        return customerData.Branches.map(branch => ({
          value: branch.BranchCode,
          label: branch.BranchCode,
        }));
      default:
        return [];
    }
  };

  const handleAddBranchPlant = () => {
    setCustomerData((prevData) => ({
      ...prevData,
      Branches: [
        ...prevData.Branches,
        {
          BranchCode: '',
          BranchName: '',
          EmailID: '',
          PinCode: '',
          CountryCode: '',
          Phone: '',
          ReportingType: '',
          ReportingTo: '',
          NoOfLicenses: 0,
          Address: '',
          ZipCode: '',
          StatusIDFK: null
        },
      ],
    }));
  };

  const handleDeleteBranchPlantInformation = (index) => {
    setCustomerData((prevData) => ({
      ...prevData,
      Branches: prevData.Branches.filter((_, i) => i !== index),
    }));
  };

  const handleBranchChange = (index, field, value) => {
    setCustomerData((prevData) => {
      const updatedBranches = prevData.Branches.map((branch, i) => {
        if (i === index) {
          return { ...branch, [field]: value };
        }
        return branch;
      });

      return {
        ...prevData,
        Branches: updatedBranches,
      };
    });
    // Clear the error for the field being edited
    setFieldErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[`Branches[${index}].${field}`]; // Clear the specific error
      return newErrors;
    });

    // Validate total licenses after updating
    if (field === 'NoOfLicenses') {
      setCustomerData((prevData) => {
        const updatedBranches = prevData.Branches.map((branch, i) => {
          if (i === index) {
            return { ...branch, [field]: value };
          }
          return branch;
        });

        const totalBranchLicenses = updatedBranches.reduce((total, branch) => {
          return total + (Number(branch.NoOfLicenses) || 0);
        }, 0);

        if (totalBranchLicenses > prevData.Company_NoOfLicenses) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [`Branches[${index}].NoOfLicenses`]: 'Total branch licenses cannot exceed Company Licenses.',
          }));
        } else {
          // Clear error when total is valid (less than or equal to company licenses)
          setFieldErrors((prevErrors) => {
            const { [`Branches[${index}].NoOfLicenses`]: removedError, ...rest } = prevErrors;
            return rest;
          });
        }

        return { ...prevData, Branches: updatedBranches };
      });
    }
  };

  // const [storageLocation, setStorageLocation] = useState([])
  // const handleAddStorage = () => {
  //   setStorageLocation(prevUnits => [...prevUnits, {}]); // Adding an empty object as a placeholder
  // };
  // const handleDeleteStorageLocation = (index) => {
  //   setStorageLocation((prevUnits) => prevUnits.filter((_, i) => i !== index))
  // }

  const handleAddStorageLocation = () => {
    setCustomerData((prevData) => ({
      ...prevData,
      Slocs: [
        ...prevData.Slocs,
        {
          StorageLocationCode: '',
          StorageLocationName: '',
          ReportingType: '',
          ReportingTo: '',
          StatusIDFK: null,
        },
      ],
    }));
  };

  const handleDeleteStorageLocation = (index) => {
    setCustomerData((prevData) => ({
      ...prevData,
      Slocs: prevData.Slocs.filter((_, i) => i !== index),
    }));
  };

  const handleStorageLocationChange = (index, field, value) => {
    setCustomerData((prevData) => {
      const updatedSlocs = prevData.Slocs.map((sloc, i) => {
        if (i === index) {
          return { ...sloc, [field]: value };
        }
        return sloc;
      });

      return {
        ...prevData,
        Slocs: updatedSlocs,
      };
    });
  };

  const [step, setStep] = useState(1); // 1 for initial step, 2 for business units
  const [fieldErrors, setFieldErrors] = useState({});

  const requiredFields = {
    basicInfo: [
      'Company_Code',
      'Company_Name',
      'Company_ContactPerson',
      'Company_EmailID',
      'Company_Mobile',
      'Company_NoOfLicenses',
      'Company_Classification',
      'Company_Address',
      'Company_ZipCode',
      'Company_StartDate',
      'Company_EndDate',
      'Company_Website',
    ],
    // assignedModules: 'AssignedModuleIDs',
    businessUnits: [
      {
        UnitName: '',
        UnitCode: '',
        // status: null,
      },
    ],
    branches: [
      {
        BranchCode: '',
        BranchName: '',
        EmailID: '',
        // PinCode: '',
        CountryCode: '',
        Phone: '',
        ReportingType: '',
        ReportingTo: '',
        NoOfLicenses: 0,
        Address: '',
        ZipCode: '',
      },
    ],
    slocs: [
      {
        StorageLocationCode: '',
        StorageLocationName: '',
        ReportingType: '',
        ReportingTo: '',
        // StatusIDFK: null,
      },
    ],
  };

  const validateFields = (data, step) => {
    const errors = {};

    // Step 1: Validate basic info fields
    if (step === 1) {
      requiredFields.basicInfo.forEach((field) => {
        if (!data[field]) {
          errors[field] = `${field.replace(/_/g, ' ')} is required.`;
        } else {
          // Additional validation based on field type
          if (field === 'Company_EmailID') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(data[field])) {
              errors[field] = 'Please enter a valid email address.';
            }
          }
        }
      });
    }

    // Step 2: Validate business units
    else if (step === 2) {
      if (Array.isArray(data.BusinessUnits)) {
        data.BusinessUnits.forEach((unit, index) => {
          if (!unit.UnitName) {
            errors[`BusinessUnits[${index}].UnitName`] = 'Unit Name is required.';
          }
          if (!unit.UnitCode) {
            errors[`BusinessUnits[${index}].UnitCode`] = 'Unit Code is required.';
          }
        });
      } else {
        errors.BusinessUnits = 'Business Units are required.';
      }
    }

    // Step 3: Validate branches
    else if (step === 3) {
      if (Array.isArray(data.Branches)) {
        data.Branches.forEach((branch, index) => {
          requiredFields.branches[0] && Object.keys(requiredFields.branches[0]).forEach((field) => {
            if (!branch[field] && field !== 'NoOfLicenses') {
              errors[`Branches[${index}].${field}`] = `${field.replace(/_/g, ' ')} is required.`;
            }
          });

          // Additional validation for NoOfLicenses
          if (branch.NoOfLicenses === null || branch.NoOfLicenses < 0) {
            errors[`Branches[${index}].NoOfLicenses`] = 'Number of Licenses must be a positive number.';
          }
        });
      } else {
        errors.Branches = 'At least one branch must be added.';
      }
    }

    // Step 4: Validate storage locations (slocs)
    else if (step === 4) {
      if (Array.isArray(data.Slocs)) {
        data.Slocs.forEach((sloc, index) => {
          requiredFields.slocs[0] && Object.keys(requiredFields.slocs[0]).forEach((field) => {
            if (!sloc[field]) {
              errors[`Slocs[${index}].${field}`] = `${field.replace(/_/g, ' ')} is required.`;
            }
          });
        });
      } else {
        errors.Slocs = 'At least one storage location must be added.';
      }
    }

    return errors; // Return the errors object
  };

  const handleNextStep = () => {

    const validationErrors = validateFields(customerData, step); // Validate based on current step

    if (Object.keys(validationErrors).length > 0) {
      setFieldErrors(validationErrors); // Set the validation errors
      return;
    }
    setStep(prevStep => (prevStep < 4 ? prevStep + 1 : prevStep)); // Move to next step if not on the last step
  };

  const handleBackStep = () => {
    setStep(prevStep => (prevStep > 1 ? prevStep - 1 : prevStep)); // Move to previous step if not on the first step
  };

  const fileInputRef = useRef(null);
  const [selectedImg, setSelectedImg] = useState(null); // State for the selected image

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const [imgChange, setImgChange] = useState(false)

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // The full base64 string includes the 'data:image/png;base64,' part
        const base64String = reader.result;

        // Remove the 'data:image/png;base64,' prefix to store only the raw base64 data
        const base64Data = base64String.split(',')[1]; // Get the actual base64 data part

        // Update state with the raw base64 data (without the prefix)
        setImgChange(true)
        setCustomerData(prevState => ({
          ...prevState,
          Image: base64Data,
        }));
      };

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };

      reader.readAsDataURL(file); // Read the file as a Data URL
    } else {
      // If no file is selected (input is cleared), reset the image field to an empty string
      setCustomerData(prevState => ({
        ...prevState,
        Image: "", // Reset image to empty string
      }));
    }
  };

  const AddCustomerManagementData = (e) => {
    e.preventDefault();

    const validationErrors = validateFields(customerData, step); // Validate based on current step
    if (Object.keys(validationErrors).length > 0) {
      setFieldErrors(validationErrors);
      return;
    }

    if (!imgChange) {
      customerData.Image = ""
    }

    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: HeaderAuthentication.postExcomatixAuth,
      body: JSON.stringify(customerData)
    };
    dispatch(postCustomerManagementDatas(requestOption, companyId, handleClose, customerManagementData, imgChange, setImgChange))
    // console.log(customerData, customFields, 'customerData')
  }

  return (
    <Modal show={open} onHide={handleClose} backdrop="static" keyboard={false} className='add-customer-modal'>
      <Modal.Header closeButton>
        <Modal.Title>{companyCode ? 'Edit Customer' : 'Add Customer'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className='add-customer-menus'>
          <Col
            className={`add-customer-menus-names ${step >= 1 ? 'step-active' : 'step-remaining'}`}
          >
            <img src={headQuarter} alt="" />
            <p>Company</p>

          </Col>
          <Col className='add-customer-dots'>
            <img src={dotImg} alt="" />
          </Col>
          <Col
            className={`add-customer-menus-names ${step >= 2 ? 'step-active' : step === 1 ? 'step-next' : 'step-remaining'}`}
          >
            <img src={businessUnit} alt="" />
            <p>Business Unit</p>
          </Col>
          <Col className='add-customer-dots'>
            <img src={dotImg} alt="" />
          </Col>
          <Col
            className={`add-customer-menus-names ${step >= 3 ? 'step-active' : step === 2 ? 'step-next' : 'step-remaining'}`}
          >
            <img src={branchInformation} alt="" />
            <p>Plant</p>
          </Col>
          <Col className='add-customer-dots'>
            <img src={dotImg} alt="" />
          </Col>
          <Col
            className={`add-customer-menus-names ${step >= 4 ? 'step-active' : step === 3 ? 'step-next' : 'step-remaining'}`}
          >
            <img src={storageLocationImg} alt="" />
            <p>Storage Location</p>
          </Col>
        </Row>
        {step === 1 && (
          <Row className='customer-form-fields'>
            <Col className='customer-form-fields-image-div'>
              <img
                // src={customerData?.Image || customerImg}
                // src={customerData?.Image ? `data:image/;base64,${customerData.Image}` : customerImg}
                src={
                  customerData?.Image
                    ? customerData.Image.startsWith('data:image/')  // Check if it's base64 with MIME type
                      ? customerData.Image  // Use as is if it's base64 with MIME type
                      : customerData.Image.includes('http')  // Check if it's a URL
                        ? customerData.Image  // Use directly if it's a server URL
                        : `data:image/;base64,${customerData.Image}`  // If base64 without MIME, prepend MIME type
                    : customerImg  // Use fallback image if no image exists
                }
                alt=""
                style={{ width: "100px", height: "100px" }}
                onClick={handleUploadClick}
              />
              <input
                type="file"
                hidden
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              <p>Upload Image</p>
            </Col>
            <Col>
              <Row>
                <Col md={4}>
                  <div>
                    <label>Company Code</label>
                    <div className='customer-company-code-div'>
                      <Form.Control
                        type="text"
                        value={customerData.Company_Code}
                        onChange={handleInputChange('Company_Code')}
                        isInvalid={!!fieldErrors.Company_Code}
                        disabled={!!companyId}
                      />

                      <OverlayTrigger
                        placement="top" // Position of the tooltip
                        overlay={<Tooltip id="tooltip-top">Generate Company Code</Tooltip>}
                      >
                        <img
                          src={autoFillImg}
                          alt="Generate Company Code"
                          // style={{ cursor: 'pointer' }}
                          style={{
                            cursor: 'pointer',
                            opacity: companyId ? 0.5 : 1,  // Reduce opacity to indicate disabled state
                            pointerEvents: companyId ? 'none' : 'auto'  // Disable clicks if companyId exists
                          }}
                          onClick={handleGenerateCodeClick}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                  {fieldErrors.Company_Code && (
                    <div className="invalid-feedback d-block">
                      {fieldErrors.Company_Code}
                    </div>
                  )}
                </Col>
                <Col md={4}>
                  <div>
                    <label>Company Name</label>
                    <Form.Control
                      type="text"
                      value={customerData.Company_Name}
                      onChange={handleInputChange('Company_Name')}
                      isInvalid={!!fieldErrors.Company_Name}
                    />
                  </div>
                  {fieldErrors.Company_Name && (
                    <div className="invalid-feedback d-block">
                      {fieldErrors.Company_Name}
                    </div>
                  )}
                </Col>
                <Col md={4}>
                  <div>
                    <label>Contact Name</label>
                    <Form.Control
                      type="text"
                      value={customerData.Company_ContactPerson}
                      onChange={handleInputChange('Company_ContactPerson')}
                      isInvalid={!!fieldErrors.Company_ContactPerson}
                    />
                  </div>
                  {fieldErrors.Company_ContactPerson && (
                    <div className="invalid-feedback d-block">
                      {fieldErrors.Company_ContactPerson}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div>
                    <label>Email ID</label>
                    <Form.Control
                      type="email"
                      value={customerData.Company_EmailID}
                      onChange={handleInputChange('Company_EmailID')}
                      isInvalid={!!fieldErrors.Company_EmailID}
                    />
                  </div>
                  {fieldErrors.Company_EmailID && (
                    <div className="invalid-feedback d-block">
                      {fieldErrors.Company_EmailID}
                    </div>
                  )}
                </Col>
                <Col md={4}>
                  <div>
                    <label>No.of.Licence</label>
                    <Form.Control
                      type="number"
                      value={customerData.Company_NoOfLicenses}
                      onChange={handleInputChange('Company_NoOfLicenses')}
                      isInvalid={!!fieldErrors.Company_NoOfLicenses}
                    />
                  </div>
                  {fieldErrors.Company_NoOfLicenses && (
                    <div className="invalid-feedback d-block">
                      {fieldErrors.Company_NoOfLicenses}
                    </div>
                  )}
                </Col>
                <Col md={4}>
                  <div>
                    <label>Contact Number</label>
                    <div className='customer-contact-fields-div'>
                      <Select
                        // className='customer-contact-select '
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={getCountryCodeDropdownData.find(option => option.label === customerData.Company_CountryCode) || null}
                        onChange={handleInputChange('Company_CountryCode')}
                        // options={contactOptions}
                        options={getCountryCodeDropdownData}
                        placeholder="+91"
                      />
                      <Form.Control
                        type="tel"
                        value={customerData.Company_Mobile}
                        onChange={handleInputChange('Company_Mobile')}
                        maxLength={15}
                        isInvalid={!!fieldErrors.Company_Mobile}
                      />
                    </div>
                  </div>
                  {fieldErrors.Company_Mobile && (
                    <div className="invalid-feedback d-block">
                      {fieldErrors.Company_Mobile}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div>
                    <label>Classification</label>
                    {/* <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      // value={selectedOption}
                      onChange={handleChange}
                      // options={contactOptions}
                      placeholder="Select"
                    /> */}
                    <Form.Control
                      type="text"
                      value={customerData.Company_Classification}
                      onChange={handleInputChange('Company_Classification')}
                      isInvalid={!!fieldErrors.Company_Classification}
                    />
                  </div>
                  {fieldErrors.Company_Classification && (
                    <div className="invalid-feedback d-block">
                      {fieldErrors.Company_Classification}
                    </div>
                  )}
                </Col>
                <Col md={4}>
                  <div>
                    <label>Address</label>
                    <Form.Control
                      type="text"
                      value={customerData.Company_Address}
                      onChange={handleInputChange('Company_Address')}
                      isInvalid={!!fieldErrors.Company_Address}
                    />
                  </div>
                  {fieldErrors.Company_Address && (
                    <div className="invalid-feedback d-block">
                      {fieldErrors.Company_Address}
                    </div>
                  )}
                </Col>
                <Col md={4}>
                  <div>
                    <label>Zip Code</label>
                    <Form.Control
                      type="number"
                      value={customerData.Company_ZipCode}
                      onChange={handleInputChange('Company_ZipCode')}
                      isInvalid={!!fieldErrors.Company_ZipCode}
                    />
                  </div>
                  {fieldErrors.Company_ZipCode && (
                    <div className="invalid-feedback d-block">
                      {fieldErrors.Company_ZipCode}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className='add-customer-calendar-container'>
                    <label>Start Date</label>
                    {/* <div className='add-customer-calendar-container'> */}
                    <DatePicker
                      className="select from-date"
                      id="date-range-picker"
                      dateFormat="dd-MM-yyyy"
                      showMonthDropdown
                      showYearDropdown
                      selectsStart
                      dropdownMode="select"
                      placeholderText='Start Date'
                      selected={customerData.Company_StartDate ? moment(customerData.Company_StartDate, 'DD-MM-YYYY').toDate() : null} // Convert string to Date object
                      // selected={customerData.Company_StartDate} // Make sure this is a Date object
                      onChange={(date) => handleDateChange(date, 'Company_StartDate')}
                      maxDate={new Date()}
                      isInvalid={!!fieldErrors.Company_StartDate}
                    />
                    <img src={calendar} alt="" />
                    {/* </div> */}
                  </div>
                  {fieldErrors.Company_StartDate && (
                    <div className="invalid-feedback d-block">
                      {fieldErrors.Company_StartDate}
                    </div>
                  )}
                </Col>
                <Col md={4}>
                  <div className='add-customer-calendar-container'>
                    <label>End Date</label>
                    {/* <div className='add-customer-calendar-container'> */}
                    <DatePicker
                      className="select end-date"
                      id="date-range-picker"
                      dateFormat="dd-MM-yyyy"
                      showMonthDropdown
                      showYearDropdown
                      selectsEnd
                      dropdownMode="select"
                      placeholderText='End Date'
                      selected={customerData.Company_EndDate ? moment(customerData.Company_EndDate, 'DD-MM-YYYY').toDate() : null} // Convert string to Date object
                      // selected={customerData.Company_EndDate} // Make sure this is a Date object
                      onChange={(date) => handleDateChange(date, 'Company_EndDate')}
                      // maxDate={new Date()}
                      isInvalid={!!fieldErrors.Company_StartDate}
                    />
                    <img src={calendar} alt="" />
                    {/* </div> */}
                  </div>
                  {fieldErrors.Company_EndDate && (
                    <div className="invalid-feedback d-block">
                      {fieldErrors.Company_EndDate}
                    </div>
                  )}
                </Col>
                <Col md={4}>
                  <div>
                    <label>Assigned Modules</label>
                    <MultiSelect
                      options={assignedModulesDropdown || []}
                      value={assignedModuleValues}
                      onChange={handleModuleChange}
                      labelledBy="Select Assigned Modules"
                      isInvalid={!!fieldErrors.AssignedModuleIDs}
                    />
                  </div>
                  {fieldErrors.AssignedModuleIDs && (
                    <div className="invalid-feedback d-block">
                      {fieldErrors.AssignedModuleIDs}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div>
                    <label>Website</label>
                    <Form.Control
                      type="text"
                      value={customerData.Company_Website}
                      onChange={handleInputChange('Company_Website')}
                      isInvalid={!!fieldErrors.Company_Website}
                    />
                  </div>
                  {fieldErrors.Company_Website && (
                    <div className="invalid-feedback d-block">
                      {fieldErrors.Company_Website}
                    </div>
                  )}
                </Col>
                {companyId && <Col md={4}>
                  <label>Status</label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={statusOptions}
                    value={statusOptions.find(option => option.value === customerData.Company_StatusIDFK) || null}
                    onChange={handleInputChange('Company_StatusIDFK')}
                    placeholder="Select"
                  />
                </Col>}
              </Row>
            </Col>
            {/* <CustomFieldsForCustomer
              customFields={customFields}
              fieldValues={fieldValues}  // Ensure fieldValues are properly passed
              setFieldValues={setFieldValues}  // Ensure this setter updates the correct state
              setCustomFields={setCustomFields}  // If you need to update custom fields dynamically
            // setCustomeLocalImage={setCustomeLocalImage}  // If you are handling image uploads locally
            /> */}

          </Row>
        )}
        {step === 2 && (
          <Row className='customer-form-fields'>
            <Col>
              {customerData.BusinessUnits.map((unit, index) => (
                <>
                  {index > 0 && <hr className='mt-4' />}
                  {index > 0 && <Row>
                    <Col className='cross-delete-img-div'>
                      <img src={crossmark} alt="" className='cross-delete-img' onClick={() => handleDeleteBusinessUnit(index)} />
                    </Col>
                  </Row>}
                  <Row>
                    <Col md={4}>
                      <div>
                        <label>Business Unit Code</label>
                        <Form.Control
                          type="text"
                          value={unit.UnitCode}
                          onChange={(e) => handleBusinessUnitChange(index, 'UnitCode', e.target.value)}
                          isInvalid={!!fieldErrors[`BusinessUnits[${index}].UnitCode`]}
                        />
                      </div>
                      {fieldErrors[`BusinessUnits[${index}].UnitCode`] && (
                        <div className="invalid-feedback d-block">
                          {fieldErrors[`BusinessUnits[${index}].UnitCode`]}
                        </div>
                      )}
                    </Col>
                    <Col md={4}>
                      <div>
                        <label>Business Unit Name</label>
                        <Form.Control
                          type="text"
                          value={unit.UnitName}
                          onChange={(e) => handleBusinessUnitChange(index, 'UnitName', e.target.value)}
                          isInvalid={!!fieldErrors[`BusinessUnits[${index}].UnitName`]}
                        />
                      </div>
                      {fieldErrors[`BusinessUnits[${index}].UnitName`] && (
                        <div className="invalid-feedback d-block">
                          {fieldErrors[`BusinessUnits[${index}].UnitName`]}
                        </div>
                      )}
                    </Col>
                    {companyId && <Col md={4}>
                      <label>Status</label>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        // value={unit.StatusIDFK}
                        value={statusOptions.find(option => option.value === unit.StatusIDFK) || null}
                        onChange={(selected) => { handleBusinessUnitChange(index, 'StatusIDFK', selected.value) }}
                        options={statusOptions}
                        placeholder="Select"
                      />
                    </Col>}
                  </Row>
                </>
              ))}
              <Row>
                <Col className='business-unit-btn'>
                  <SbAddBtn btnName="Add Business Unit" onClickEffect={handleAddBusinessUnit} />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {step === 3 && (
          <Row className='customer-form-fields customer-branch-plant-form-fields'>
            <Col>
              {customerData.Branches.map((branch, index) => (
                <>
                  {index > 0 && <hr className='mt-4' />}
                  {index > 0 && <Row>
                    <Col className='cross-delete-img-div'>
                      <img src={crossmark} alt="" className='cross-delete-img' onClick={() => handleDeleteBranchPlantInformation(index)} />
                    </Col>
                  </Row>}
                  <Row className='customer-form-fields customer-branch-plant-form-fields'>
                    <Col>
                      <Row>
                        <Col md={4}>
                          <div>
                            <label>Branch/Plant Code</label>
                            <Form.Control
                              type="text"
                              value={branch.BranchCode}
                              onChange={(e) => handleBranchChange(index, 'BranchCode', e.target.value)}
                              isInvalid={!!fieldErrors[`Branches[${index}].BranchCode`]}
                            />
                          </div>
                          {fieldErrors[`Branches[${index}].BranchCode`] && (
                            <div className="invalid-feedback d-block">
                              {fieldErrors[`Branches[${index}].BranchCode`]}
                            </div>
                          )}
                        </Col>
                        <Col md={4}>
                          <div>
                            <label>Branch/Plant Name</label>
                            <Form.Control
                              type="text"
                              value={branch.BranchName}
                              onChange={(e) => handleBranchChange(index, 'BranchName', e.target.value)}
                              isInvalid={!!fieldErrors[`Branches[${index}].BranchName`]}
                            />
                          </div>
                          {fieldErrors[`Branches[${index}].BranchName`] && (
                            <div className="invalid-feedback d-block">
                              {fieldErrors[`Branches[${index}].BranchName`]}
                            </div>
                          )}
                        </Col>
                        <Col md={4}>
                          <div>
                            <label>Email</label>
                            <Form.Control
                              type="email"
                              value={branch.EmailID}
                              onChange={(e) => handleBranchChange(index, 'EmailID', e.target.value)}
                              isInvalid={!!fieldErrors[`Branches[${index}].EmailID`]}
                            />
                          </div>
                          {fieldErrors[`Branches[${index}].EmailID`] && (
                            <div className="invalid-feedback d-block">
                              {fieldErrors[`Branches[${index}].EmailID`]}
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <label>Contact Number</label>
                            <div className='customer-contact-fields-div'>
                              <Select
                                // className='customer-contact-select '
                                className="react-select-container"
                                classNamePrefix="react-select"
                                value={getCountryCodeDropdownData.find(option => option.label === branch.CountryCode) || null}
                                onChange={(selected) => handleBranchChange(index, 'CountryCode', selected.value)}
                                // options={contactOptions}
                                options={getCountryCodeDropdownData}
                                placeholder="Select"
                              />
                              <Form.Control
                                type="tel"
                                value={branch.Phone}
                                onChange={(e) => handleBranchChange(index, 'Phone', e.target.value)}
                                isInvalid={!!fieldErrors[`Branches[${index}].Phone`]}
                              />
                            </div>
                            {fieldErrors[`Branches[${index}].Phone`] && (
                              <div className="invalid-feedback d-block">
                                {fieldErrors[`Branches[${index}].Phone`]}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col md={4}>
                          <div>
                            <label>Reporting Type</label>
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              // value={branch.ReportingType}
                              value={reportingTypeOptionsPlant.find(option => option.value === branch.ReportingType) || null}
                              // onChange={(selected) => handleBranchChange(index, 'ReportingType', selected.value)}
                              onChange={(selected) => {
                                handleBranchChange(index, 'ReportingType', selected.value);
                                // Optionally clear ReportingTo if the reporting type changes
                                handleBranchChange(index, 'ReportingTo', null);
                              }}
                              options={reportingTypeOptionsPlant}
                              placeholder="Select"
                              isInvalid={!!fieldErrors[`Branches[${index}].ReportingType`]}
                            />
                          </div>
                          {fieldErrors[`Branches[${index}].ReportingType`] && (
                            <div className="invalid-feedback d-block">
                              {fieldErrors[`Branches[${index}].ReportingType`]}
                            </div>
                          )}
                        </Col>
                        <Col md={4}>
                          <div>
                            <label>Reporting To</label>
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              value={branch.ReportingTo ? { value: branch.ReportingTo, label: branch.ReportingTo } : null}
                              onChange={(selected) => handleBranchChange(index, 'ReportingTo', selected.value)}
                              // options={getUnitCodesOptions()}
                              options={getUnitCodesOptions(branch.ReportingType)} // Pass selected ReportingType here
                              placeholder="Select"
                              isInvalid={!!fieldErrors[`Branches[${index}].ReportingTo`]}
                            />
                          </div>
                          {fieldErrors[`Branches[${index}].ReportingTo`] && (
                            <div className="invalid-feedback d-block">
                              {fieldErrors[`Branches[${index}].ReportingTo`]}
                            </div>
                          )}
                        </Col>

                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <label>No.of.Licenses</label>
                            <Form.Control
                              type="number"
                              value={branch.NoOfLicenses}
                              onChange={(e) => handleBranchChange(index, 'NoOfLicenses', e.target.value)}
                              isInvalid={!!fieldErrors[`Branches[${index}].NoOfLicenses`]}
                            />
                          </div>
                          {fieldErrors[`Branches[${index}].NoOfLicenses`] && (
                            <div className="invalid-feedback d-block">
                              {fieldErrors[`Branches[${index}].NoOfLicenses`]}
                            </div>
                          )}
                        </Col>
                        <Col md={4}>
                          <div>
                            <label>Address</label>
                            <Form.Control
                              type="text"
                              value={branch.Address}
                              onChange={(e) => handleBranchChange(index, 'Address', e.target.value)}
                              isInvalid={!!fieldErrors[`Branches[${index}].Address`]}
                            />
                          </div>
                          {fieldErrors[`Branches[${index}].Address`] && (
                            <div className="invalid-feedback d-block">
                              {fieldErrors[`Branches[${index}].Address`]}
                            </div>
                          )}
                        </Col>
                        <Col md={4}>
                          <div>
                            <label>Zip Code</label>
                            <Form.Control
                              type="text"
                              value={branch.ZipCode}
                              onChange={(e) => handleBranchChange(index, 'ZipCode', e.target.value)}
                              isInvalid={!!fieldErrors[`Branches[${index}].ZipCode`]}
                            />
                          </div>
                          {fieldErrors[`Branches[${index}].ZipCode`] && (
                            <div className="invalid-feedback d-block">
                              {fieldErrors[`Branches[${index}].ZipCode`]}
                            </div>
                          )}
                        </Col>
                      </Row>
                      {companyId && <Row>
                        <Col md={4}>
                          <label>Status</label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            // value={branch.StatusIDFK}
                            value={statusOptions.find(option => option.value === branch.StatusIDFK) || null}
                            onChange={(selected) => handleBranchChange(index, 'StatusIDFK', selected.value)}
                            options={statusOptions}
                            placeholder="Select"
                          />
                        </Col>
                      </Row>}

                    </Col>

                  </Row>
                </>
              ))}
              <Row>
                <Col className='business-unit-btn'>
                  <SbAddBtn btnName="Add Branch / Plant"
                    onClickEffect={handleAddBranchPlant}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {step === 4 && (
          <Row className='customer-form-fields'>
            <Col>
              {customerData.Slocs.map((sloc, index) => (
                <>
                  {index > 0 && <hr className='mt-4' />}
                  {index > 0 && <Row>
                    <Col className='cross-delete-img-div'>
                      <img src={crossmark} alt="" className='cross-delete-img' onClick={() => handleDeleteStorageLocation(index)} />
                    </Col>
                  </Row>}
                  <Row>
                    <Col>
                      <Row>
                        <Col md={4}>
                          <div>
                            <label>Storage Location Code</label>
                            <Form.Control
                              type="text"
                              value={sloc.StorageLocationCode}
                              onChange={(e) => handleStorageLocationChange(index, 'StorageLocationCode', e.target.value)}
                              isInvalid={!!fieldErrors[`Slocs[${index}].StorageLocationCode`]}
                            />
                          </div>
                          {fieldErrors[`Slocs[${index}].StorageLocationCode`] && (
                            <div className="invalid-feedback d-block">
                              {fieldErrors[`Slocs[${index}].StorageLocationCode`]}
                            </div>
                          )}
                        </Col>
                        <Col md={4}>
                          <div>
                            <label>Storage Location Name</label>
                            <Form.Control
                              type="text"
                              value={sloc.StorageLocationName}
                              onChange={(e) => handleStorageLocationChange(index, 'StorageLocationName', e.target.value)}
                              isInvalid={!!fieldErrors[`Slocs[${index}].StorageLocationName`]}
                            />
                          </div>
                          {fieldErrors[`Slocs[${index}].StorageLocationName`] && (
                            <div className="invalid-feedback d-block">
                              {fieldErrors[`Slocs[${index}].StorageLocationName`]}
                            </div>
                          )}
                        </Col>
                        <Col md={4}>
                          <div>
                            <label>Reporting Type</label>
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              // value={sloc.ReportingType}
                              value={reportingTypeOptionsSloc.find(option => option.value === sloc.ReportingType) || null}
                              // onChange={(selected) => handleStorageLocationChange(index, 'ReportingType', selected.value)}
                              onChange={(selected) => {
                                handleStorageLocationChange(index, 'ReportingType', selected.value);
                                // Optionally clear ReportingTo if the reporting type changes
                                handleStorageLocationChange(index, 'ReportingTo', null);
                              }}
                              options={reportingTypeOptionsSloc}
                              placeholder="Select"
                              isInvalid={!!fieldErrors[`Slocs[${index}].ReportingType`]}
                            />
                          </div>
                          {fieldErrors[`Slocs[${index}].ReportingType`] && (
                            <div className="invalid-feedback d-block">
                              {fieldErrors[`Slocs[${index}].ReportingType`]}
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <label>Reporting To</label>
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              value={sloc.ReportingTo ? { value: sloc.ReportingTo, label: sloc.ReportingTo } : null}
                              onChange={(selected) => handleStorageLocationChange(index, 'ReportingTo', selected.value)}
                              // options={getPlantCodesOptions()}
                              options={getPlantCodesOptions(sloc.ReportingType)} // Pass the selected ReportingType
                              placeholder="Select"
                              isInvalid={!!fieldErrors[`Slocs[${index}].ReportingTo`]}
                            />
                          </div>
                          {fieldErrors[`Slocs[${index}].ReportingTo`] && (
                            <div className="invalid-feedback d-block">
                              {fieldErrors[`Slocs[${index}].ReportingTo`]}
                            </div>
                          )}
                        </Col>
                        {companyId && <Col md={4}>
                          <label>Status</label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            // value={sloc.StatusIDFK}
                            value={statusOptions.find(option => option.value === sloc.StatusIDFK) || null}
                            onChange={(selected) => handleStorageLocationChange(index, 'StatusIDFK', selected.value)}
                            options={statusOptions}
                            placeholder="Select"
                          />
                        </Col>}
                      </Row>

                    </Col>
                  </Row>
                </>
              ))}
              <Row>
                <Col className='business-unit-btn'>
                  <SbAddBtn btnName="Add SLOC" onClickEffect={handleAddStorageLocation} />
                </Col>
              </Row>
            </Col>

          </Row>

        )}
      </Modal.Body >
      <Modal.Footer>
        <SbCancelBtn btnName="Cancel" onClickEffect={handleClose} />
        {step > 1 && <SBPreviousBtn btnName="Previous" onClickEffect={handleBackStep} />}
        {step < 4 ? <SBSaveUpdateBtn btnName="Next" onClickEffect={handleNextStep} /> : <SBSaveUpdateBtn btnName="Save" onClickEffect={AddCustomerManagementData} />}
      </Modal.Footer>
    </Modal >
  );
};

export default AddCustomerModal;