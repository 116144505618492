import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import { useDispatch } from "react-redux";
import * as HeaderAuthentication from '../../HeaderAuthentication'
import { postCustomerClassificationDatas } from "../../../redux/action";
import { ADDED_BY_ID } from "../../Constants";

const enablingOptions = [
    { value: "8", label: "Active" },
    { value: "9", label: "Inactive" }
]
const AddCustomerClassification = (props) => {
    let dispatch = useDispatch();

    const classificationId = props.classificationId;
    const singleCustomerClassificationData = props.singleCustomerClassificationData;
    const [classificationName, setClassificationName] = useState("");
    const [status, setStatus] = useState(null);
    const [error, setError] = useState("");

    const handleSelectChange = (option) => {
        setStatus(option);
    };

    useEffect(() => {

        if (classificationId && singleCustomerClassificationData) {
            console.log(singleCustomerClassificationData[0]?.Status, 'singleCustomerClassificationData[0]?.Status')
            setClassificationName(singleCustomerClassificationData[0]?.CustomerClassificationName);
            const statusOption = enablingOptions.find(option => option.label === singleCustomerClassificationData[0]?.Status);
            setStatus(statusOption || null);
        } else {
            // Reset fields for adding new classification
            setClassificationName("");
            setStatus(null);
        }
    }, [classificationId, singleCustomerClassificationData]);

    const AddCustomerClassificationData = (e) => {
        e.preventDefault();
        if (!classificationName) {
            setError("Customer Classification is required.");
            return;
        }
        const data = {
            CustomerClassificationID: classificationId,
            CustomerClassificationName: classificationName,
            StatusID: parseInt(status?.value),
            AddedByID: ADDED_BY_ID
        }
        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };
        dispatch(postCustomerClassificationDatas(requestOption, props.handleCloseModal, classificationId, props.getData));
    }
    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{classificationId ? 'Edit Customer Classification' : 'Add Customer Classification'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Customer Classification</label>
                                        <Form.Control
                                            type="text"
                                            value={classificationName}
                                            onChange={(e) => {
                                                setClassificationName(e.target.value);
                                                setError("")
                                            }}
                                            isInvalid={!!error}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {error}
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                            </Row>
                            {classificationId && <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Enable / Disable</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                            value={status}
                                            onChange={handleSelectChange}
                                        />
                                    </div>
                                </Col>
                            </Row>}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={AddCustomerClassificationData} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddCustomerClassification;
