import React from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';

const enablingOptions = [
    { value: "Enable", label: "Enable" },
    { value: "Disable", label: "Disable" }
]

const AddFinancialApproval = (props) => {

    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Financial Approval</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Approval Levels</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <div>
                                        <label>Title</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Department</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <div>
                                        <label>Approval Order</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Approval Condition</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Low Threshold</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Not To Exceed Threshold</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Status</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={props.handleNextStep} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddFinancialApproval;
