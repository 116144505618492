import { toast } from "react-toastify";
import { baseURL } from "../components/BaseUrl";
import * as types from "./actionType";
import Swal from "sweetalert2";
import * as HeaderAuthentication from '../../src/components/HeaderAuthentication'
import { ADDED_BY_ID } from "../components/Constants";

// Customer Management
const postCustomerManagementData = () => ({
    type: types.post_Customer_Data,
});
const postCustomFieldValuesForCompany = () => ({
    type: types.post_Custom_Fields_Value_For_Company,
});
const getAssignedModulesDropdownData = (assignedModulesDropdown) => ({
    type: types.get_Assigned_Modules,
    payload: assignedModulesDropdown,
});
const getCompanyData = (companyData) => ({
    type: types.get_Company_Data,
    payload: companyData,
});
const getSingleCompanyData = (singleCustomerData) => ({
    type: types.get_Single_Company_Data,
    payload: singleCustomerData,
});
const getCountryCodeDropdownData = (getCountryCodeDropdownData) => ({
    type: types.get_Country_Code,
    payload: getCountryCodeDropdownData,
});
const getCompanyDropdownData = (getCompanyDropdown) => ({
    type: types.get_Company_Dropdown,
    payload: getCompanyDropdown,
});
const getGeneratedCompanyCode = (getGeneratedCompanyCode) => ({
    type: types.get_generated_Company_Code,
    payload: getGeneratedCompanyCode,
});
const getCustomFieldsForCompany = (getCompanyCustomFields) => ({
    type: types.get_Custom_Fields_For_Company,
    payload: getCompanyCustomFields,
});
// Customer Management

// User Management
const getUserManagementData = (userData) => ({
    type: types.get_User_Data,
    payload: userData,
});
// User Management

// Settings Department
const postDepartmentData = () => ({
    type: types.post_Department_Data
})
const getDepartmentData = (departmentData) => ({
    type: types.get_Department_Data,
    payload: departmentData,
});
const getSingleDepartmentData = (singleDepartmentData) => ({
    type: types.get_Single_Department_Data,
    payload: singleDepartmentData,
})
// Settings Department

// Settings Customer Classification
const postCustomerClassificationData = () => ({
    type: types.post_Customer_Classification_Data
})

const getCustomerClassificationData = (customerClassificationData) => ({
    type: types.get_Customer_Classification_Data,
    payload: customerClassificationData,
})
const getSingleCustomerClassificationData = (singleCustomerClassificationData) => ({
    type: types.get_Single_Customer_Classification_Data,
    payload: singleCustomerClassificationData,
})
// Settings Customer Classification

// Settings Designation
const getDesignationData = (designationData) => ({
    type: types.get_Designation_Data,
    payload: designationData
})
const postDesignationData = () => ({
    type: types.post_Designation_Data
})
const getDepartmentDropdownData = (departmentDropdown) => ({
    type: types.get_Department_Dropdown,
    payload: departmentDropdown,
});
const getReportingDesignationDropdownData = (reportingDesignationDropdown) => ({
    type: types.get_Reporting_Designation_Dropdown,
    payload: reportingDesignationDropdown,
});
const getSingleDesignationData = (singleDesignationData) => ({
    type: types.get_Single_Designation_Data,
    payload: singleDesignationData,
});
// Settings Designation

// Settings Issue Types
const getIssueTypesData = (issueTypesData) => ({
    type: types.get_Issue_Type_Data,
    payload: issueTypesData,
});
const getSingleIssueTypesData = (singleIssueTypesData) => ({
    type: types.get_Single_Issue_Types_Data,
    payload: singleIssueTypesData,
});
const postIssueTypesData = () => ({
    type: types.post_Issue_Types_Data,
});
// Settings Issue Types

// Settings SLA
const getSettingsSlaData = (SettingSlaData) => ({
    type: types.get_Settings_SLA_Data,
    payload: SettingSlaData,
})
const getSingleSettingsSlaData = (SingleSettingSlaData) => ({
    type: types.get_Single_Settings_SLA_Data,
    payload: SingleSettingSlaData,
})
const postSettingsSlaData = () => ({
    type: types.post_Settings_SLA_Data,
})
// Settings SLA

// Settings Module
const getModuleData = (moduleData) => ({
    type: types.get_Module_Data,
    payload: moduleData,
})
const getSingleModuleData = (singleModuleData) => ({
    type: types.get_Single_Module_Data,
    payload: singleModuleData,
})
const postModuleData = () => ({
    type: types.post_Modules_Data,
})
// Settings Module

// Settings Sub Module
const getSubModuleData = (subModuleData) => ({
    type: types.get_Sub_Module_Data,
    payload: subModuleData,
})
const getSingleSubModuleData = (singleSubModuleData) => ({
    type: types.get_Single_Sub_Module_Data,
    payload: singleSubModuleData,
})
const getSubModuleDropdown = (subModuleDropdown) => ({
    type: types.get_SubModule_Dropdown,
    payload: subModuleDropdown,
})
const postSubModuleData = () => ({
    type: types.post_Sub_Modules_Data,
})
// Settings Sub Module

// Settings System Numbering
const getSystemNumberingData = (systemNumberingData) => ({
    type: types.get_System_Numbering,
    payload: systemNumberingData,
})
const postSystemNumberingData = () => ({
    type: types.post_System_Numbering,
})
// Settings System Numbering

// Settings Work Queues Rules
const postSettingsWorkQueueData = () => ({
    type: types.post_Settings_WorkQueues_Rule_Data,
})
const getSystemWorkQueuesRulesData = (settingsWorkQueuesRulesData) => ({
    type: types.get_Settings_WorkQueues_Rule,
    payload: settingsWorkQueuesRulesData,
})
const getSettingsSingleWorkQueuesRulesData = (settingsSingleWorkQueuesRulesData) => ({
    type: types.get_Settings_Single_WorkQueues_Rule,
    payload: settingsSingleWorkQueuesRulesData,
})
const getSettingsWorkQueuesRulesFieldNameData = (getSettingsWorkQuesRulesFieldNameData) => ({
    type: types.get_Settings_WorkQueues_FieldName_Rule,
    payload: getSettingsWorkQuesRulesFieldNameData,
})
// Settings Work Queues Rules

// Settings Role
const getRolesDatas = (getRolesData) => ({
    type: types.get_Roles_Data,
    payload: getRolesData,
})
const getSingleRolesDatas = (getSingleRolesData) => ({
    type: types.get_Single_Roles_Data,
    payload: getSingleRolesData,
})
const postRolesData = () => ({
    type: types.post_Roles_Data,
})
// Settings Role

// Settings Custom Fields
const getCustomFieldsData = (getCustomFields) => ({
    type: types.get_CustomFields_Data,
    payload: getCustomFields,
})
const getSingleCustomFieldsData = (getSingleCustomFields) => ({
    type: types.get_Single_CustomFields_Data,
    payload: getSingleCustomFields,
})
const postCustomFieldsData = () => ({
    type: types.post_CustomFields_Data,
})
// Settings Custom Fields

// Settings Leave Policy
const postLeavePolicyData = () => ({
    type: types.post_Leave_Policy_Data,
})
const getSettingsLeavePolicyData = (getLeavePolicyData) => ({
    type: types.get_Leave_Policy_Data,
    payload: getLeavePolicyData,
})
const getSettingsSingleLeavePolicyData = (getSingleLeavePolicyData) => ({
    type: types.get_Single_Leave_Policy_Data,
    payload: getSingleLeavePolicyData,
})
// Settings Leave Policy

// Customer Management
export const postCustomerManagementDatas = (
    postRequest,
    companyId,
    cancelModal,
    customerManagementData,
    imgChange,
    setImgChange,
    customFields
) => {
    return function (dispatch) {
        const url = companyId ?
            `${baseURL}/companyservices/api/company/PostUpdateCompany`
            :
            `${baseURL}/companyservices/api/company/PostAddCompany`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postCustomerManagementData());
                    // if (customFields.length !== 0) {
                    //     // Filter customFields to send only the required fields
                    //     const filteredCustomFields = customFields.map(field => {
                    //         return {
                    //             CustomField_Value: field.CustomField_Value,
                    //             CustomField_IDFK: field.CustomFieldsID, // or use field.CustomField_IDFK if already set
                    //             AddedByID: ADDED_BY_ID // Assuming AddedByID is static or comes from a context
                    //         };
                    //     });
                    //     console.log(filteredCustomFields, 'filteredCustomFields')
                    //     let postCustomData = {
                    //         method: "POST",
                    //         mode: "cors",
                    //         headers: HeaderAuthentication.postExcomatixAuth,
                    //         body: JSON.stringify(filteredCustomFields),
                    //     };
                    //     dispatch(postCustomFieldValuesOfCompany(postCustomData, cancelModal));
                    // }
                    customerManagementData()
                    cancelModal();
                    if (imgChange) {
                        setImgChange(false)
                    }
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};

export const postCustomFieldValuesOfCompany = (
    postRequest,
    companyId,
    cancelModal,
    customerManagementData
) => {
    return function (dispatch) {
        // const url = companyId ?
        //     `${baseURL}/excomatixservices/api/CustomFieldValue/PostUpdateCustomFieldValue`
        //     :
        //     `${baseURL}/excomatixservices/api/CustomFieldValue/PostAddCustomFieldValue`
        fetch(`${baseURL}/excomatixservices/api/CustomFieldValue/PostAddCustomFieldValue`, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postCustomFieldValuesForCompany());
                    // customerManagementData()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};

export const loadAssignedModulesDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/modules/GetModules`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "ModulesID": "",
                "StatusID": 1,
                "Modules_Name": ""
            }),
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Modules) {
                    dispatch(
                        getAssignedModulesDropdownData(
                            resp.Modules.map((data) => {
                                return { value: data.ModuleID, label: data.Module, statusID: data.StatusID };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadCountryCodeDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/companyservices/api/company/PostCountryCode`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({}),
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.CountryCodes) {
                    dispatch(
                        getCountryCodeDropdownData(
                            resp.CountryCodes.map((data) => {
                                return { value: data.CountryCodeID, label: data.CountryCode };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadCompanyData = (
    setLoading
) => {
    return function (dispatch) {
        fetch(`${baseURL}/companyservices/api/company/GetCompanies`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "CompanyID": 0,
                "Company_StatusIDFK": 0,
                "Company_Code": "",
                "Company_Name": "",
                "PageSize": 10,
                "PageIndex": 1
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getCompanyData(resp.Customers));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSingleCompanyData = (
    companyCode
) => {
    return function (dispatch) {
        fetch(`${baseURL}/companyservices/api/company/postCompanyDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "Company_Code": companyCode,
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleCompanyData(resp));
            })
            .catch((err) => { console.log(err) });
    };
};

export const loadCompanyDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/companyservices/api/company/GetCompanies`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "CompanyID": 0,
                "Company_StatusIDFK": 0,
                "Company_Code": "",
                "Company_Name": "",
                "PageSize": 10,
                "PageIndex": 1
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Customers) {
                    dispatch(
                        getCompanyDropdownData(
                            resp.Customers.map((data) => {
                                return { value: data.CompanyID, label: data.CompanyName, statusID: 5 };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};

export const loadGeneratedCompanyCode = () => {
    return function (dispatch) {
        // fetch(`${baseURL}/excomatixservices/api/SystemNumbering/GetModuleNextGeneratedCode?ModuleName=Customer Management`, HeaderAuthentication.getRequestOptions)
        fetch(`${baseURL}/excomatixservices/api/SystemNumbering/GetModuleNextGeneratedCode?ModuleID=2`, HeaderAuthentication.getRequestOptions)
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getGeneratedCompanyCode(resp))
            })
    }
}

export const loadCustomFieldsForCompany = () => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/CustomField/GetCustomFieldDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ CustomFields_ModuleIDFK: 2 })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getCustomFieldsForCompany(resp.CustomFields));
                // setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};

// Customer Management

// User Management
export const loadUserManagementData = (
    setLoading
) => {
    return function (dispatch) {
        fetch(`${baseURL}/userservices/api/User/GetUserDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "DOB": "2000-01-01",
                "DOJ": "2024-01-01"
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getUserManagementData(resp.Customers));
                // setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};

// User Management

// Settings Department
export const postDepartmentDatas = (
    postRequest,
    cancelModal,
    deptData,
    deptID
) => {
    return function (dispatch) {
        const url = deptID ?
            `${baseURL}/excomatixservices/api/department/PostUpdateDepartment`
            :
            `${baseURL}/excomatixservices/api/department/PostAddDepartment`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postDepartmentData());
                    deptData()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};

export const loadDepartmentData = (setLoading, pageSize, currentPage) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/department/GetDepartmentDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "DeptID": 0,
                "StatusID": 0,
                "DepartmentName": "",
                "PageSize": pageSize,
                "PageIndex": currentPage
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getDepartmentData(resp.Departments));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};

export const loadSingleDepartmentData = (deptID) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/department/GetDepartmentDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "DeptID": deptID
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleDepartmentData(resp.Departments))
            })
            .catch((err) => { console.log(err) });
    };
};
// Settings Department

// Settings Customer Classification
export const loadCustomerClassificationData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/CustomerClassification/GetCustomerClassificationDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getCustomerClassificationData(resp.CustomerClassifications))
                setLoading(false)
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSingleCustomerClassificationData = (clsID) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/CustomerClassification/GetCustomerClassificationDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "CustomerClassificationID": clsID
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleCustomerClassificationData(resp.CustomerClassifications))
            })
            .catch((err) => { console.log(err) });
    };
};

export const postCustomerClassificationDatas = (
    postRequest,
    cancelModal,
    clsID,
    getData
) => {
    return function (dispatch) {
        if (clsID) {
            fetch(`${baseURL}/excomatixservices/api/CustomerClassification/PostUpdateCustomerClassification`, postRequest)
                .then((resp) => resp.json())
                .then((resp) => {
                    if (resp.Success === 1) {
                        dispatch(postCustomerClassificationData());
                        getData();
                        cancelModal();
                        Swal.fire({
                            title: 'Success',
                            text: resp.Msg,
                            icon: 'success',
                        });
                    } else {
                        // toast.error(resp.Error);
                        const errorMessage = resp.Msg; // Extract error message
                        Swal.fire({
                            title: 'Failed',
                            text: errorMessage,
                            icon: 'error',
                        });
                    }
                })
                .catch((err) => {
                    toast.error(`${err}`);
                });
        } else {
            fetch(`${baseURL}/excomatixservices/api/CustomerClassification/PostAddCustomerClassification`, postRequest)
                .then((resp) => resp.json())
                .then((resp) => {
                    if (resp.Success === 1) {
                        dispatch(postCustomerClassificationData());
                        getData();
                        cancelModal();
                        Swal.fire({
                            title: 'Success',
                            text: resp.Msg,
                            icon: 'success',
                        });
                    } else {
                        // toast.error(resp.Error);
                        const errorMessage = resp.Msg; // Extract error message
                        Swal.fire({
                            title: 'Failed',
                            text: errorMessage,
                            icon: 'error',
                        });
                    }
                })
                .catch((err) => {
                    toast.error(`${err}`);
                });
        }
    }
};

// Settings Customer Classification

// Settings Designation
export const loadDesignationData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/Designation/GetDesignationDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getDesignationData(resp.Designation))
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};

export const loadSingleDesignationData = (desgID) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/Designation/GetDesignationDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "DesignationID": desgID })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleDesignationData(resp.Designation))
            })
            .catch((err) => { console.log(err) });
    };
};

export const postDesignationDatas = (
    postRequest,
    cancelModal,
    getDesgData,
    desgID
) => {
    return function (dispatch) {
        const url = desgID ?
            `${baseURL}/excomatixservices/api/Designation/PostUpdateDesignation`
            :
            `${baseURL}/excomatixservices/api/Designation/PostAddDesignation`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postDesignationData());
                    getDesgData()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};

export const loadDepartmentDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/department/GetDepartmentDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "statusID": 3
            }),
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Departments) {
                    dispatch(
                        getDepartmentDropdownData(
                            resp.Departments.map((data) => {
                                return { value: data.DeptID, label: data.DepartmentName };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};

export const loadReportingDesignationDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/Designation/GetDesignationDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "statusID": 10
            }),
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Designation) {
                    dispatch(
                        getReportingDesignationDropdownData(
                            resp.Designation.map((data) => {
                                return { value: data.DesignationID, label: data.DesignationName };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};


// Settings Designation

// Settings Issue Types
export const loadIssueTypesData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/IssueType/GetIssueTypeDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getIssueTypesData(resp.IssueTypes));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};

export const loadSingleIssueTypesData = (issueId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/IssueType/GetIssueTypeDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                IssueTypeID: issueId
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleIssueTypesData(resp.IssueTypes))
            })
            .catch((err) => { console.log(err) });
    };
};

export const postIssueTypesDatas = (
    postRequest,
    cancelModal,
    issueTypes,
    issueTypeId
) => {
    return function (dispatch) {
        const url = issueTypeId ?
            `${baseURL}/excomatixservices/api/IssueType/PostUpdateIssueType`
            :
            `${baseURL}/excomatixservices/api/IssueType/PostAddIssueType`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postIssueTypesData());
                    issueTypes()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};

// Settings Issue Types

// Settings SLA
export const loadSettingsSlaData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/SLACategories/GetSLACategoriesDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSettingsSlaData(resp['SLA Categories']));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSingleSettingsSlaData = (settSlaId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/SLACategories/GetSLACategoriesDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "SLACategoriesID": settSlaId, })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleSettingsSlaData(resp['SLA Categories']))
            })
            .catch((err) => { console.log(err) });
    };
};

export const postSettingsSlaCategoriesData = (
    postRequest,
    cancelModal,
    getSettingsSlaData,
    settSlaId
) => {
    return function (dispatch) {
        const url = settSlaId ?
            `${baseURL}/excomatixservices/api/SLACategories/PostUpdateSLACategories`
            :
            `${baseURL}/excomatixservices/api/SLACategories/PostAddSLACategories`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postSettingsSlaData());
                    getSettingsSlaData()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};

// Settings SLA

// Settings  Module
export const loadModuleData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/modules/GetModules`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getModuleData(resp.Modules));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSingleModuleData = (moduleId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/modules/GetModules`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "ModulesID": moduleId })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleModuleData(resp.Modules));
            })
            .catch((err) => { console.log(err) });
    };
};

export const postModulesDatas = (
    postRequest,
    cancelModal,
    modulesData,
    moduleId
) => {
    return function (dispatch) {
        const url = moduleId ?
            `${baseURL}/excomatixservices/api/Modules/PostUpdateModule`
            :
            `${baseURL}/excomatixservices/api/Modules/PostAddModule`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postModuleData());
                    modulesData()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};

// Settings Module

// Settings Sub Module
export const loadSubModuleData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/SubModules/GetAllSubModules`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSubModuleData(resp['Sub Modules']));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSingleSubModuleData = (subModuleId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/SubModules/GetAllSubModules`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "SubModulesID": subModuleId })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleSubModuleData(resp['Sub Modules']));
            })
            .catch((err) => { console.log(err) });
    };
};

export const postSubModulesDatas = (
    postRequest,
    cancelModal,
    subModuleDatas,
    subModuleId
) => {
    return function (dispatch) {
        const url = subModuleId ?
            `${baseURL}/excomatixservices/api/SubModules/PostUpdateSubModules`
            :
            `${baseURL}/excomatixservices/api/SubModules/PostAddSubModules`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postSubModuleData());
                    subModuleDatas()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    console.log("Error Message:", errorMessage); // Optional: Log to console
                    toast.error(errorMessage);
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};

export const loadSubModulesDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/SubModules/GetAllSubModules`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "StatusID": 18
            }),
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp["Sub Modules"]) {
                    dispatch(
                        getSubModuleDropdown(
                            resp["Sub Modules"].map((data) => {
                                return { value: data.SubModulesID, label: data.SubModulesName, statusID: 18 };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};

// Settings Sub Module

// Settings System Numbering
export const loadSystemNumberingData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/SystemNumbering/GetSystemNumberingDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSystemNumberingData(resp.SystemNumberings));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};

export const postSystemNumberingDatas = (
    postRequest,
    cancelModal,
    systemNumbering,
    // subModuleId
) => {
    return function (dispatch) {
        // const url = subModuleId ?
        //     `${baseURL}/excomatixservices/api/SubModules/PostUpdateSubModules`
        //     :
        //     `${baseURL}/excomatixservices/api/SubModules/PostAddSubModules`
        fetch(`${baseURL}/excomatixservices/api/SystemNumbering/PostAddSystemNumbering`, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postSystemNumberingData());
                    systemNumbering()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    console.log("Error Message:", errorMessage); // Optional: Log to console
                    toast.error(errorMessage);
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};


// Settings System Numbering

// Settings Work Queues Rules
export const postSettingsWorkQueuesDatas = (
    postRequest,
    cancelModal,
    getSettingsWorkQueuesRulesData,
    workqueueId
) => {
    return function (dispatch) {
        const url = workqueueId ?
            `${baseURL}/excomatixservices/api/WorkQueue/PostUpdateWorkQueue`
            :
            `${baseURL}/excomatixservices/api/WorkQueue/PostAddWorkQueue`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postSettingsWorkQueueData());
                    getSettingsWorkQueuesRulesData()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                // toast.error(`${err}`);
                console.log(err)
            });
    }
};

export const loadSettingsWorkQueuesRulesData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/WorkQueue/GetWorkQueueDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSystemWorkQueuesRulesData(resp.WorkQueue));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSettingsSingleWorkQueuesRulesData = (workqueueId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/WorkQueue/GetWorkQueueDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "WorkQueueID": workqueueId })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSettingsSingleWorkQueuesRulesData(resp.WorkQueue));
                // setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};

export const loadSettingsWorkQueuesRulesFieldNameData = (moduleId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/WorkQueue/GetModuleFieldDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "CompanyID": 17, "ModuleID": moduleId, "StatusID": '34,26' })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.ModuleField) {
                    dispatch(
                        getSettingsWorkQueuesRulesFieldNameData(
                            resp.ModuleField.map((data) => {
                                return { value: data.ModuleID, label: data.MouleFieldAliasName, IsCustom: data.IsCustom, ModuleFieldID: data.ModuleFieldID };
                            })
                        )
                    );
                }
            })
    }
}


// Settings Work Queues Rules

//  Settings Role
export const loadSettingsRolesData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/roles/PostRoleDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getRolesDatas(resp.Roles));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSingleSettingsRolesData = (roleId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/roles/PostRoleDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ RoleID: roleId })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleRolesDatas(resp.Roles));
            })
            .catch((err) => { console.log(err) });
    };
};

export const postRolesDatas = (
    postRequest,
    cancelModal,
    getRoles,
    roleId
) => {
    return function (dispatch) {
        const url = roleId ?
            `${baseURL}/excomatixservices/api/roles/PostupdateRole`
            :
            `${baseURL}/excomatixservices/api/roles/PostAddRole`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postRolesData());
                    getRoles()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};
//  Settings Role

// Settings Custom Fields
export const loadCustomFieldsData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/CustomField/GetCustomFieldDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getCustomFieldsData(resp.CustomFields));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSingleCustomFieldsData = (customFieldsId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/CustomField/GetCustomFieldDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ CustomFields_IDPK: customFieldsId })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleCustomFieldsData(resp.CustomFields));
                // setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};

export const postCustomFieldsDatas = (
    postRequest,
    cancelModal,
    getCustomField,
    customFieldsId
) => {
    return function (dispatch) {

        const url = customFieldsId ?
            `${baseURL}/excomatixservices/api/CustomField/PostUpdateCustomField`
            :
            `${baseURL}/excomatixservices/api/CustomField/PostAddCustomField`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postCustomFieldsData());
                    getCustomField()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg;
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};
// Settings Custom Fields

// Settings Leave Policy
export const postLeavePolicyDatas = (
    postRequest,
    cancelModal,
    leavePolicyData,
    leavePolicyId
) => {
    return function (dispatch) {

        const url = leavePolicyId ?
            `${baseURL}/excomatixservices/api/LeavePolicy/PostUpdateLeavePolicy`
            :
            `${baseURL}/excomatixservices/api//LeavePolicy/PostAddLeavePolicy`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postLeavePolicyData());
                    leavePolicyData()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg;
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};
export const loadSettingsLeavePolicyData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/LeavePolicy/PostLeavePolicyDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSettingsLeavePolicyData(resp['Leave Policies']));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSettingsSingleLeavePolicyData = (leavePolicyId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/LeavePolicy/PostLeavePolicyDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "LeavePolicyID": leavePolicyId
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSettingsSingleLeavePolicyData(resp['Leave Policies']));
                // setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
// Settings Leave Policy
