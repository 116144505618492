import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem
} from '@mui/material';
import SortIcon from '../../assets/images/Dashboard/chevron-down.svg';
import ascToDesc from '../../assets/images/CustomerManagement/ascToDesc.svg';
import descToAsc from '../../assets/images/CustomerManagement/descToAsc.svg';
import { SbFilterBtn } from '../Buttons/Buttons';
import calendar from '../../assets/images/Dashboard/calendar-icon.svg';
import { Col, Dropdown, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import searchIcon from '../../assets/images/Dashboard/search-icon.svg'
import columnFilter from '../../assets/images/CustomerManagement/columnFilter.svg'
import valueFilter from '../../assets/images/CustomerManagement/valueFilter.svg'
import NoOfLicenseExperingModal from './NoOfLicenseExperingModal';
import AssignToModal from './AssignToModal';
import DescriptionModal from './DescriptionModal';
import PriorityStatusBadgeWithDropdown from '../Buttons/PriorityStatusBadgeWithDropdown';
import PaginationComponent from '../PaginationComponent';
import WqColumnFilterModal from './WqColumnFilterModal';
import WqValueFilterModal from './WqValueFilterModal';

const data = [
    {
        priorityStatus: '01',
        rule: 'No.of licenses expering in next 30 days ',
        modules: 'License Management',
        workQueueCount: '03',
        AssignTo: 'Vijaykumar',
        currentCount: '03',
        assignDate: '16/08/2024',
        description: 'DescriptionDescriptionDescription',
    },
    {
        priorityStatus: '01',
        rule: 'No.of licenses expering in next 30 days ',
        modules: 'License Management',
        workQueueCount: '03',
        AssignTo: 'Vijaykumar',
        currentCount: '03',
        assignDate: '16/08/2024',
        description: 'DescriptionDescriptionDescription',
    },
    {
        priorityStatus: '01',
        rule: 'No.of licenses expering in next 30 days ',
        modules: 'License Management',
        workQueueCount: '03',
        AssignTo: 'Vijaykumar',
        currentCount: '03',
        assignDate: '16/08/2024',
        description: 'DescriptionDescriptionDescription',
    },
    {
        priorityStatus: '01',
        rule: 'No.of licenses expering in next 30 days ',
        modules: 'License Management',
        workQueueCount: '03',
        AssignTo: 'Vijaykumar',
        currentCount: '03',
        assignDate: '16/08/2024',
        description: 'DescriptionDescriptionDescription',
    },
    {
        priorityStatus: '01',
        rule: 'No.of licenses expering in next 30 days ',
        modules: 'License Management',
        workQueueCount: '03',
        AssignTo: 'Vijaykumar',
        currentCount: '03',
        assignDate: '16/08/2024',
        description: 'DescriptionDescriptionDescription',
    },
    {
        priorityStatus: '01',
        rule: 'No.of licenses expering in next 30 days ',
        modules: 'License Management',
        workQueueCount: '03',
        AssignTo: 'Vijaykumar',
        currentCount: '03',
        assignDate: '16/08/2024',
        description: 'DescriptionDescriptionDescription',
    },
    {
        priorityStatus: '01',
        rule: 'No.of licenses expering in next 30 days ',
        modules: 'License Management',
        workQueueCount: '03',
        AssignTo: 'Vijaykumar',
        currentCount: '03',
        assignDate: '16/08/2024',
        description: 'DescriptionDescriptionDescription',
    },
    {
        priorityStatus: '01',
        rule: 'No.of licenses expering in next 30 days ',
        modules: 'License Management',
        workQueueCount: '03',
        AssignTo: 'Vijaykumar',
        currentCount: '03',
        assignDate: '16/08/2024',
        description: 'DescriptionDescriptionDescription',
    },
    {
        priorityStatus: '01',
        rule: 'No.of licenses expering in next 30 days ',
        modules: 'License Management',
        workQueueCount: '03',
        AssignTo: 'Vijaykumar',
        currentCount: '03',
        assignDate: '16/08/2024',
        description: 'DescriptionDescriptionDescription',
    },
    {
        priorityStatus: '01',
        rule: 'No.of licenses expering in next 30 days ',
        modules: 'License Management',
        workQueueCount: '03',
        AssignTo: 'Vijaykumar',
        currentCount: '03',
        assignDate: '16/08/2024',
        description: 'DescriptionDescriptionDescription',
    },
    {
        priorityStatus: '01',
        rule: 'No.of licenses expering in next 30 days ',
        modules: 'License Management',
        workQueueCount: '03',
        AssignTo: 'Vijaykumar',
        currentCount: '03',
        assignDate: '16/08/2024',
        description: 'DescriptionDescriptionDescription',
    },
];

const columns = [
    { id: 'priorityStatus', label: 'PRIORITY STATUS', align: 'left', minWidth: '160px' },
    { id: 'rule', label: 'RULE', align: 'left', minWidth: '120px' },
    { id: 'modules', label: 'MODULES', align: 'left', minWidth: '180px' },
    { id: 'AssignTo', label: 'ASSIGN TO', align: 'left', minWidth: '120px' },
    { id: 'currentCount', label: 'CURRENT COUNT', align: 'left', minWidth: '160px' },
    { id: 'assignDate', label: 'ASSIGN DATE', align: 'left', minWidth: '150px' },
    { id: 'description', label: 'DESCRIPTION', align: 'left', minWidth: '150px' },
];

const WorkQueuesViewAssignedTable = (props) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [actionAnchorEl, setActionAnchorEl] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Set initial rows per page
    const [paginatedData, setPaginatedData] = useState([]);

    const handleRequestSort = (event, property) => {
        setCurrentSortColumn(property);
        setAnchorEl(event.currentTarget);
    };

    const handleSortOrderChange = (order) => {
        setOrder(order);
        setOrderBy(currentSortColumn);
        setAnchorEl(null);
    };

    const handleActionClick = (event, customer) => {
        setSelectedCustomer(customer);
        setActionAnchorEl(event.currentTarget);
    };

    const handleActionClose = () => {
        setActionAnchorEl(false);
    };

    const handleMenuItemClick = (action) => {
        if (action === 'edit') {
            console.log('Edit Details for:', selectedCustomer);
            // Implement edit functionality here
        } else if (action === 'view') {
            console.log('View Details for:', selectedCustomer);
            // Implement view functionality here
        }
        handleActionClose();
    };

    const sortedData = React.useMemo(() => {
        const stabilizedData = data.map((el, index) => [el, index]);
        stabilizedData.sort((a, b) => {
            const orderModifier = order === 'asc' ? 1 : -1;
            if (a[0][orderBy] < b[0][orderBy]) {
                return -1 * orderModifier;
            }
            if (a[0][orderBy] > b[0][orderBy]) {
                return 1 * orderModifier;
            }
            return a[1] - b[1];
        });
        return stabilizedData.map((el) => el[0]);
    }, [data, order, orderBy]);

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [showAssignToModal, setShowAssignToModal] = useState(false);
    const [showDescriptionModal, setShowDescriptionModal] = useState(false);
    const [showColumnFilterModal, setShowColumnFilterModal] = useState(false);
    const [showValueFilterModal, setShowValueFilterModal] = useState(false);
    const [clickedCell, setClickedCell] = useState({ rowId: null, columnId: null }); // Track clicked cell

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleOpenColumnFilterModal = () => {
        setShowFilterMenu(false);
        setShowColumnFilterModal(true);
    };

    const handleCloseColumnFilterModal = () => {
        setShowColumnFilterModal(false);
    };
    const handleOpenValueFilterModal = () => {
        setShowFilterMenu(false);
        setShowValueFilterModal(true);
    };

    const handleCloseValueFilterModal = () => {
        setShowValueFilterModal(false);
    };

    const handleOpenAssignModal = () => {
        setShowAssignToModal(true);
    }

    const handleCloseAssignModal = () => {
        setShowAssignToModal(false);
    }
    const handleOpenDescriptionModal = () => {
        setShowDescriptionModal(true);
    }

    const handleCloseDescriptionModal = () => {
        setShowDescriptionModal(false);
    }

    const handleWorkQueueCountClick = (customer) => {
        setSelectedCustomer(customer);
        setIsModalOpen(true);
    };

    // Pagination logic
    useEffect(() => {
        const start = page * rowsPerPage; // Updated page logic to 0-based
        const end = start + rowsPerPage;
        setPaginatedData(sortedData.slice(start, end));
    }, [sortedData, page, rowsPerPage]);

    const handlePageChange = (newPage) => {
        setPage(newPage - 1); // Handle 1-based to 0-based page index for slicing
    };
    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to the first page when rows per page changes
    };

    return (
        <>
            <Row className='customermanagement-btns-container'>
                <Col md={3} className='customermanagement-backbtn-div'>
                    <span>Work Queues</span>
                </Col>
                <Col md={9} className='customermanagement-search-filter'>
                    <div className='customermanagement-header-btns filter-btn'>
                        <SbFilterBtn btnName="Filters" onClickEffect={toggleFilterMenu} />
                        {showFilterMenu && (
                            <Dropdown.Menu show className='filter-dropdown-options'>
                                <Dropdown.Item onClick={handleOpenColumnFilterModal}>
                                    <img src={columnFilter} alt="Column Filter" />&nbsp;&nbsp;
                                    Column Filter
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleOpenValueFilterModal}>
                                    <img src={valueFilter} alt="Value Filter" />&nbsp;&nbsp;
                                    Value Filter
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        )}
                    </div>
                    <Col md={3} className='filters-col'>
                        <div className='customermanagement-search'>
                            <Form.Control type="search" placeholder='Search' />
                            <img src={searchIcon} alt="Search" />
                        </div>
                    </Col>
                    <Col md={3} className='filters-col'>
                        <div className='calendar-container'>
                            <DatePicker
                                className="select from-date"
                                dateFormat="dd/MM/yyyy"
                                selectsStart
                                placeholderText='From'
                                startDate={fromDate}
                                endDate={toDate}
                                onChange={date => handleFromDateChange(date)}
                                maxDate={new Date()}
                            />
                            <img src={calendar} alt="Calendar" />
                        </div>
                    </Col>
                    <Col md={3} className='filters-col'>
                        <div className='calendar-container'>
                            <DatePicker
                                className="select to-date"
                                dateFormat="dd/MM/yyyy"
                                selectsEnd
                                placeholderText='To'
                                startDate={fromDate}
                                endDate={toDate}
                                onChange={date => handleToDateChange(date)}
                                maxDate={new Date()}
                            />
                            <img src={calendar} alt="Calendar" />
                        </div>
                    </Col>
                    {/* <SbAddBtn btnName="Add SLA" onClickEffect={handleOpenModal} /> */}
                </Col>
            </Row>
            <div className='customermanagement-table-div'>
                <TableContainer component={Paper} sx={{ maxHeight: 440 }} className='table-container'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }} className='table-header-div'>
                                            {column.label}
                                            {column.sortable !== false && (
                                                <IconButton
                                                    size="small"
                                                    onClick={(event) => handleRequestSort(event, column.id)}
                                                >
                                                    <img src={SortIcon} alt="Sort" />
                                                </IconButton>
                                            )}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.map((customer) => (
                                <TableRow key={customer.id}>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            onClick={
                                                column.id === 'currentCount'
                                                    ? () => handleWorkQueueCountClick(customer)
                                                    : column.id === 'description'
                                                        ? () => handleOpenDescriptionModal(customer)
                                                        : undefined
                                            }
                                            style={column.id === 'currentCount' || column.id === 'description' ? { cursor: 'pointer' } : {}}
                                            // className={clickedCell.rowId === customer.id && clickedCell.columnId === column.id ? 'clicked-value' : ''}
                                            // onMouseDown={() => setClickedCell({ rowId: customer.id, columnId: column.id })}
                                            // onMouseUp={() => setClickedCell({ rowId: null, columnId: null })}
                                            // onMouseLeave={() => setClickedCell({ rowId: null, columnId: null })}
                                        >
                                            {column.id === 'priorityStatus' ? (
                                                <PriorityStatusBadgeWithDropdown />
                                            ) : (
                                                // <span
                                                //     onClick={() => {
                                                //         setClickedCell({ rowId: customer.id, columnId: column.id }); // Track clicked cell
                                                //         handleOpenValueFilterModal(); // Open modal
                                                //     }}
                                                //     style={{ cursor: "pointer" }}
                                                // >
                                                //     {customer[column.id]}
                                                // </span>
                                                customer[column.id]
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        <MenuItem onClick={() => handleSortOrderChange('asc')}>
                            <img src={ascToDesc} alt="" />&nbsp;&nbsp;
                            Ascending to Descending
                        </MenuItem>
                        <MenuItem onClick={() => handleSortOrderChange('desc')}>
                            <img src={descToAsc} alt="" />&nbsp;&nbsp;
                            Descending to Ascending
                        </MenuItem>
                    </Menu>
                </TableContainer>
                {/* Use PaginationComponent */}
                <PaginationComponent
                    totalItems={data.length}
                    itemsPerPage={rowsPerPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
                {isModalOpen && <NoOfLicenseExperingModal open={isModalOpen} handleClose={handleCloseModal} />}
                {showAssignToModal && <AssignToModal open={showAssignToModal} handleClose={handleCloseAssignModal} />}
                {showDescriptionModal && <DescriptionModal open={showDescriptionModal} handleClose={handleCloseDescriptionModal} />}
                {
                    showColumnFilterModal && (
                        <WqColumnFilterModal
                            showColumnFilterModal={showColumnFilterModal}
                            handleCloseColumnFilterModal={handleCloseColumnFilterModal}
                        />
                    )
                }
                {
                    showValueFilterModal && (
                        <WqValueFilterModal
                            showValueFilterModal={showValueFilterModal}
                            handleCloseValueFilterModal={handleCloseValueFilterModal}
                        />
                    )
                }
            </div>
        </>
    );
};

export default WorkQueuesViewAssignedTable;
