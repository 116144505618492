import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SbAddBtn, SBSaveUpdateBtn } from "../Buttons/Buttons";
import Select from 'react-select';
import { useState } from "react";

const AddSlocModal = (props) => {

    const [storageLocation, setStorageLocation] = useState([])
    const handleAddStorage = () => {
        setStorageLocation(prevUnits => [...prevUnits, {}]); // Adding an empty object as a placeholder
    };

    return (
        <>
            <Modal show={props.openSlocModal} onHide={props.handleCloseSlocModal} className='add-customer-modal add-user-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Select Storage Locations (05)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>User Name</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <div>
                                        <label>Plant / Branch</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            // options={contactOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div>
                                        <label>Slocs (02)</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            // options={contactOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <div>
                                        <label>Plant / Branch</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            // options={contactOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div>
                                        <label>Slocs (02)</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            // options={contactOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {storageLocation.map((unit, index) => (
                                <Row>
                                    <Col md={6}>
                                        <div>
                                            <label>Plant / Branch</label>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                // value={selectedOption}
                                                // onChange={handleChange}
                                                // options={contactOptions}
                                                placeholder="Select"
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div>
                                            <label>Slocs (02)</label>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                // value={selectedOption}
                                                // onChange={handleChange}
                                                // options={contactOptions}
                                                placeholder="Select"
                                            />
                                        </div>
                                    </Col>
                                </Row>))}
                            <Row>
                                <Col className='business-unit-btn'>
                                    <SbAddBtn btnName="Add Branch" onClickEffect={handleAddStorage} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal.Body >
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseSlocModal} />
                    <SBSaveUpdateBtn btnName="Add Slocs" />
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default AddSlocModal;