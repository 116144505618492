// integration started on 21/10/2024

// Customer Management
export const post_Customer_Data = "post_Customer_Data";
export const get_Assigned_Modules = "get_Assigned_Modules";
export const get_Company_Data = "get_Company_Data";
export const get_Single_Company_Data = "get_Single_Company_Data";
export const get_Country_Code = "get_Country_Code";
export const get_Company_Dropdown = "get_Company_Dropdown";
export const get_generated_Company_Code = "get_generated_Company_Code";
export const get_Custom_Fields_For_Company = "get_Custom_Fields_For_Company";
export const post_Custom_Fields_Value_For_Company = "post_Custom_Fields_Value_For_Company";
// Customer Management

// User Management
export const get_User_Data = "get_User_Data";
// User Management

// Settings Department
export const post_Department_Data = "post_Department_Data";
export const get_Department_Data = "get_Department_Data";
export const get_Single_Department_Data = "get_Single_Department_Data";
// Settings Department

// Settings Customer Classification
export const post_Customer_Classification_Data = "post_Customer_Classification_Data";
export const get_Customer_Classification_Data = "get_Customer_Classification_Data";
export const get_Single_Customer_Classification_Data = "get_Single_Customer_Classification_Data";
// Settings Customer Classification

// Settings Designation
export const get_Designation_Data = "get_Designation_Data";
export const post_Designation_Data = "post_Designation_Data";
export const get_Department_Dropdown = "get_Department_Dropdown";
export const get_Reporting_Designation_Dropdown = "get_Reporting_Designation_Dropdown";
export const get_Single_Designation_Data = "get_Single_Designation_Data";
// Settings Designation

// Settings Issue Types
export const get_Issue_Type_Data = "get_Issue_Type_Data";
export const post_Issue_Types_Data = "post_Issue_Types_Data";
export const get_Single_Issue_Types_Data = "get_Single_Issue_Types_Data";
// Settings Issue Types

// Settings SLA
export const get_Settings_SLA_Data = "get_Settings_SLA_Data";
export const post_Settings_SLA_Data = "post_Settings_SLA_Data";
export const get_Single_Settings_SLA_Data = "get_Single_Settings_SLA_Data";
// Settings SLA

// Settings Module
export const post_Modules_Data = "post_Modules_Data";
export const get_Module_Data = "get_Module_Data";
export const get_Single_Module_Data = "get_Single_Module_Data";
// Settings Module

// Settings Sub Module
export const post_Sub_Modules_Data = "post_Sub_Modules_Data";
export const get_Sub_Module_Data = "get_Sub_Module_Data";
export const get_Single_Sub_Module_Data = "get_Single_Sub_Module_Data";
export const get_SubModule_Dropdown = "get_SubModule_Dropdown";
// Settings Sub Module

// Settings System Numbering
export const get_System_Numbering = "get_System_Numbering";
export const post_System_Numbering = "post_System_Numbering";
// Settings System Numbering

// Settings Work Queues Rules
export const get_Settings_WorkQueues_Rule = "get_Settings_WorkQueues_Rule";
export const get_Settings_Single_WorkQueues_Rule = "get_Settings_Single_WorkQueues_Rule";
export const get_Settings_WorkQueues_FieldName_Rule = "get_Settings_WorkQueues_FieldName_Rule";
export const post_Settings_WorkQueues_Rule_Data = "post_Settings_WorkQueues_Rule_Data";
// Settings Work Queues Rules

// Settings Role
export const get_Roles_Data = "get_Roles_Data";
export const get_Single_Roles_Data = "get_Single_Roles_Data";
export const post_Roles_Data = "post_Roles_Data";
// Settings Role

// Settings Custom Fields
export const get_CustomFields_Data = "get_CustomFields_Data";
export const get_Single_CustomFields_Data = "get_Single_CustomFields_Data";
export const post_CustomFields_Data = "post_CustomFields_Data";
// Settings Custom Fields

// Settings Leave Policy
export const post_Leave_Policy_Data = "post_Leave_Policy_Data";
export const get_Leave_Policy_Data = "get_Leave_Policy_Data";
export const get_Single_Leave_Policy_Data = "get_Single_Leave_Policy_Data";
// Settings Leave Policy