import React, { useRef, useState } from 'react'
import { Button, Carousel, Col, Form, Row } from 'react-bootstrap';

// region Local Import
import otp_bg_img from './assets/images/Login/otp-img.svg'
import security_bg_img from './assets/images/Login/security-img.svg'
import companySmallLogo from './assets/images/Sidebar/company-small-logo.svg';
import companyLogo from './assets/images/Sidebar/company-logo.svg';
import { Link } from 'react-router-dom';

// endregion

function Forgetpassword() {
    const navigateToDashBoard = () => {
        window.location.href = '/dashboard'
    }

    const [otp, setOtp] = useState(new Array(6).fill(''));
    const inputRefs = useRef(new Array(6));

    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value.length === 1 && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && otp[index] === '') {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };
    return (
        <div className='signin-main-container'>
            <Row className='lm-signin-login-row'>

                <Col md={6} className='lm-signin-form-col'>
                    <div
                    //  className='lm-signin-image-col'
                    >
                        <div className='forget-password-company-logo'>
                            <img src={companyLogo} alt="Company Logo" className="forget-password-company-logo" />
                        </div>
                        <div className='lm-bg-img-div security-img'>
                            <img src={security_bg_img} alt="" className='lm-bg-img p-0' />
                        </div>
                    </div>
                    <h4 className="lm-wlcm-txt">Enter your One-Time Password to verify your account</h4>
                    <span classname="small-text">A One-Time Password has been sent to karthikarthi@@gmail.com</span>
                    <Form className='lm-login-form'>
                        <div className='otp-input-box'>
                            {otp.map((value, index) => (
                                <Form.Control
                                    key={index}
                                    type="password"
                                    value={value}
                                    maxLength={1}
                                    className="form-control-otp-input"
                                    ref={(input) => (inputRefs.current[index] = input)}
                                    onChange={(e) => handleOtpChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                />
                            ))}
                        </div>
                        <div className='lm-login-form-btn forget-password-verify-btn'>
                            {/* <p className='lm-pswd-reset'>Forgot password?</p> */}
                            <Button >Enter Validate OTP Code</Button>
                        </div>
                        <div className='resend-otp-div'>
                            <span>Didn't Receive Code?&nbsp;&nbsp;
                                <p>Resend</p>
                            </span>
                        </div>
                        <div class="divider-container">
                            <div class="line"></div>
                            <p class="text">Or continue with</p>
                            <div class="line"></div>
                        </div>
                        <div className='back-to-signin-div'>
                            <p>Back To <Link to='/'>Sign in</Link></p>
                        </div>
                    </Form>
                </Col>
                <Col md={6}
                    //  className='lm-signin-image-col'
                    className='forget-password-second-col'>
                    {/* <div className='lm-logo-img-div' style={{ height: "15%" }}>
                        <p>CRM dashboard uses line charts to visualize
                            <br /> customer related metrics and trends over time</p>
                    </div> */}
                    <div className='lm-bg-img-div'>
                        <img src={otp_bg_img} alt="" className='lm-bg-img' />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Forgetpassword
