import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../Buttons/Buttons";
import Select from 'react-select';
import attachmentImg from '../../assets/images/attachmentImg.svg'
import { useRef, useState } from "react";

const statusOptions = [
    { value: "Pending", label: "Pending" },
    { value: "In-Process", label: "In-Process" },
    { value: "Resolved", label: "Resolved" },
    { value: "Re-Open", label: "Re-Open" },
    { value: "Cancel", label: "Cancel" },
]
const ticketTypeOptions = [
    { value: "Complaint Ticket", label: "Complaint Ticket" },
    { value: "Escalation Ticket", label: "Escalation Ticket" }
]

const issueTypeOptions = [
    { value: "Technical", label: "Technical" },
    { value: "Service", label: "Service" }
]

const AddComplaintEsaclation = (props) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectStatus, setSelectStatus] = useState(statusOptions[0]);
    const fileInputRef = useRef(null);
    const [ticketType, setTicketType] = useState("");
    const [issueType, setIssueType] = useState("");
    const handleTicketType = (options) => {
        setTicketType(options)
    }

    const handleIssueType = (options) => {
        setIssueType(options)
    }

    const handleChangeStatus = (options) => {
        setSelectStatus(options)
    }

    // Handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            console.log('Selected file:', file);
        }
    };

    // Trigger file input click when attachment button is clicked
    const handleAttachmentClick = () => {
        fileInputRef.current.click();
    };
    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-user-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Complaints</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Ticket Type</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            value={ticketType}
                                            onChange={handleTicketType}
                                            options={ticketTypeOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Company Name</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            // options={contactOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Reported By</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            // options={contactOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Branch</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            // options={contactOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Priority</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            // options={contactOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Issue Type</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            value={issueType}
                                            onChange={handleIssueType}
                                            options={issueTypeOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Email</label>
                                        <Form.Control type="email" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Contact</label>
                                        <Form.Control type="tel" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Zip Code</label>
                                        <Form.Control type="number" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Address</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Assigned To</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>TAT</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4} className="d-flex justify-content-start align-items-end">
                                    <div className="dropdown add-single-bulk attachment-section">
                                        <label>&nbsp;</label>
                                        <button
                                            className="btn btn-add-save-update attachment-btn"
                                            id="adduser-single-blulk"
                                            onClick={handleAttachmentClick}
                                        >
                                            <img src={attachmentImg} alt="" />&nbsp;&nbsp;
                                            <span>Attachment</span>
                                            <div className="attachment-note">
                                                <small>(jpg, PDF, word doc) Less than 5MB</small>
                                            </div>
                                        </button>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                        {/* {selectedFile && <div className="attachment-note"><small>{selectedFile.name}</small></div>} */}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Status</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            value={selectStatus}
                                            onChange={handleChangeStatus}
                                            options={statusOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <div>
                                        <label>Remark</label>
                                        <Form.Control type="tel" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal.Body >
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={props.handleNextStep} />
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default AddComplaintEsaclation;