import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../Buttons/Buttons";
import DatePicker from "react-datepicker";
import calendar from '../../assets/images/Dashboard/calendar-icon.svg'
import Select from 'react-select';
import { useRef, useState } from "react";
import attachmentImg from '../../assets/images/attachmentImg.svg'

const currencyOptions = [
    { value: "USD", label: "US$" },
    { value: "EUR", label: "€" },
    { value: "GBP", label: "£" },
    { value: "INR", label: "₹" },
    { value: "JPY", label: "¥" },
];


const AddSLA = (props) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);

    // Handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            console.log('Selected file:', file);
        }
    };

    // Trigger file input click when attachment button is clicked
    const handleAttachmentClick = () => {
        fileInputRef.current.click();
    };
    return (
        <>
            <Modal show={props.open} onHide={props.handleClose} className='add-customer-modal add-user-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Service Level Agreement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>SLA Type</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            // options={contactOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Company Name</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            // options={contactOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>SLA Category</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            // options={contactOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Value Type</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            options={currencyOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Baseline</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Targe%</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Target Value</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4} className="d-flex justify-content-start align-items-end">
                                    <div className="dropdown add-single-bulk attachment-section">
                                        <label>&nbsp;</label>
                                        <button
                                            className="btn btn-add-save-update attachment-btn"
                                            id="adduser-single-blulk"
                                            onClick={handleAttachmentClick}
                                        >
                                            <img src={attachmentImg} alt="" />&nbsp;&nbsp;
                                            <span>Attachment</span>
                                            <div className="attachment-note">
                                                <small>(jpg, PDF, word doc) Less than 5MB</small>
                                            </div>
                                        </button>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                        {/* {selectedFile && <div className="attachment-note"><small>{selectedFile.name}</small></div>} */}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className='add-customer-calendar-container'>
                                        <label>Start Date</label>
                                        {/* <div className='add-customer-calendar-container'> */}
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            selectsRange
                                            placeholderText='Start Date'
                                            // startDate={fromDate}
                                            // onChange={(data) => setSelectSearchDrop(data, "date")}
                                            maxDate={new Date()}
                                        />
                                        <img src={calendar} alt="" />
                                        {/* </div> */}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className='add-customer-calendar-container'>
                                        <label>End Date</label>
                                        {/* <div className='add-customer-calendar-container'> */}
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            selectsRange
                                            placeholderText='End Date'
                                            // startDate={fromDate}
                                            // onChange={(data) => setSelectSearchDrop(data, "date")}
                                            maxDate={new Date()}
                                        />
                                        <img src={calendar} alt="" />
                                        {/* </div> */}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Assigned To</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            // options={contactOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Description</label>
                                        <Form.Control as="textarea" rows={2} />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal.Body >
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={props.handleNextStep} />
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default AddSLA;